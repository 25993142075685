import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Observable, catchError, debounceTime, distinctUntilChanged, of, switchMap } from 'rxjs';
import { ApiService } from '../../../core/services/api.service';
import { DeliverymodeComponent } from '../deliverymode/deliverymode.component';
import { FoodMenuComponent } from '../food-menu.component';

interface LocationData {
  id: string;
  formattedAddress: string;
  location: {
    latitude: number;
    longitude: number;
  };
}

@Component({
  selector: 'app-search-bar',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss'
})
export class SearchBarComponent {
  searchTerm: string = '';
  searchPlaceId: string = '';
  searchResults: { placeId: string, text: string }[] = [];
  showResults: boolean = false;

  @Output() closeDelivery= new EventEmitter<any>();

  constructor(public apiService: ApiService, private deliveryModeComponent: DeliverymodeComponent,
    private foodMenuComponent:FoodMenuComponent
  ) { }

  search(): void {
    if (this.searchTerm.length > 0) {
      this.showResults = true;
      this.fetchPlacePrediction().subscribe(results => {
        this.searchResults = results;
      });
    } else {
      this.showResults = false;
    }
  }

  fetchPlacePrediction(): Observable<{ placeId: string, text: string }[]> {
    return this.apiService.getMethod(`/location/maps/predict?search=${this.searchTerm}`).pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(response => {
        const suggestions: any[] = response?.data?.[0]?.suggestions || [];
        const placeIdTextArray = suggestions.map(suggestion => {
          const placeId = suggestion.placePrediction.placeId;
          const text = suggestion.placePrediction.text.text;
          return { placeId, text };
        });
        return of(placeIdTextArray);
      })
    );
  }

  getPlaceDetails(): Observable<{ placeId: string, text: string }[]> {
    return this.apiService.getMethod(`/location/maps/place?placeId=${this.searchPlaceId}`).pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(response => {
        const suggestions: any[] = response?.data?.[0]?.suggestions || [];
        const placeIdTextArray = suggestions.map(suggestion => {
          const placeId = suggestion.placePrediction.placeId;
          const text = suggestion.placePrediction.text.text;
          return { placeId, text };
        });
        return of(placeIdTextArray);
      }),
      catchError(error => {
        console.error('Error fetching results:', error);
        return of([]);
      })
    );
  }

  selectResult(result: { placeId: string, text: string }): void {
    this.searchTerm = result.text;
    this.showResults = false;
    this.searchPlaceId = result.placeId;

    this.apiService.getMethod(`/location/maps/place?placeId=${result.placeId}`).subscribe({
      next: (response: { data: LocationData[] }) => {
        const locationData = response.data[0];
        localStorage.setItem('selectedLocation', JSON.stringify(locationData));

        this.deliveryModeComponent.closeSearchBar();
        // this.foodMenuComponent.closeDeliveryMode();
        this.closeDelivery.emit({action:'closeDelivery'})
        // Reset searchTerm and searchResults
        this.searchTerm = '';
        this.searchResults = [];
        this.showResults = false;

      },
      error: error => {
        console.error('Error fetching location data:', error);
      }
    });
  }
}
