import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Config } from '../config';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private backend_url: string = Config.base_url;
  
  // httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json'});


  constructor(private http: HttpClient) { }

  public getMethod(endPoint: string): Observable<any> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
      'Content-Type': 'application/json'
      // Add any other headers you need
    });
    return this.http.get<any>(this.backend_url + endPoint, { headers });
  }

  public postMethod(endPoint: string, requestBody: any): Observable<any> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
      'Content-Type': 'application/json'
    });
    return this.http.post(this.backend_url + endPoint, requestBody, { headers });

  }
  public patchMethod(endPoint: string, requestBody: any): Observable<any> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE',
      'Content-Type': 'application/json'
    });
    return this.http.patch(this.backend_url + endPoint, requestBody, { headers });

  }

  public deleteMethod(endPoint:string):Observable<any> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
      'Content-Type': 'application/json'
      // Add any other headers you need
    });

    return this.http.delete(this.backend_url + endPoint, { headers , observe: 'response' })
  }
}
