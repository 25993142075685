<!-- <footer class="footer__area footer--1">
    <div class="footer__wrapper bg__cat--1 section-padding--lg">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-3 col-sm-12">
                    <div class="footer">
                        <h2 class="ftr__title">About HyperApps</h2>
                        <div class="footer__inner">
                            <div class="ftr__details">
                                <p>Lorem ipsum dolor sit amconsectetur adipisicing elit,</p>
                                <div class="ftr__address__inner">
                                    <div class="ftr__address">
                                        <div class="ftr__address__icon">
                                            <i class="zmdi zmdi-home"></i>
                                        </div>
                                        <div class="frt__address__details">
                                            <p>Elizabeth Tower. 6th Floor Medtown, New York</p>
                                        </div>
                                    </div>
                                    <div class="ftr__address">
                                        <div class="ftr__address__icon">
                                            <i class="zmdi zmdi-phone"></i>
                                        </div>
                                        <div class="frt__address__details">
                                            <p><a href="#">+088 01673-453290</a></p>
                                            <p><a href="#">+088 01773-458290</a></p>
                                        </div>
                                    </div>
                                    <div class="ftr__address">
                                        <div class="ftr__address__icon">
                                            <i class="zmdi zmdi-email"></i>
                                        </div>
                                        <div class="frt__address__details">
                                            <p><a href="#">HyperApps&#64;gemail.com</a></p>
                                        </div>
                                    </div>
                                </div>
                                <ul class="social__icon">
                                    <li><a href="#"><i class="zmdi zmdi-facebook"></i></a></li>
                                    <li><a href="#"><i class="zmdi zmdi-google"></i></a></li>
                                    <li><a href="#"><i class="zmdi zmdi-instagram"></i></a></li>
                                    <li><a href="#"><i class="zmdi zmdi-rss"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 col-sm-12 md--mt--40 sm--mt--40">
                    <div class="footer">
                        <h2 class="ftr__title">Opening Time</h2>
                        <div class="footer__inner">
                            <ul class="opening__time__list">
                                <li>Saturday<span>.......</span>9am to 11pm</li>
                                <li>Sunday<span>.......</span>9am to 11pm</li>
                                <li>Monday<span>.......</span>9am to 11pm</li>
                                <li>Tuesday<span>.......</span>9am to 11pm</li>
                                <li>Wednesday<span>.......</span>9am to 11pm</li>
                                <li>Thursday<span>.......</span>9am to 11pm</li>
                                <li>Friday<span>.......</span>9am to 11pm</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright bg--theme">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="copyright__inner">
                        <div class="cpy__right--left">
                            <p>&#64; All Right Reserved. &nbsp;&nbsp;&nbsp;&nbsp;<a href="">HyperApps</a></p>
                        </div>
                        <div class="cpy__right--right">
                            <a href="#">
                                <img src="../../../../assets/images/icon/shape/2.png" alt="payment images">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer> -->

<div class="copyright bg--theme" style="background-color: #fff;border-top:solid 1px #d50c0d; position:  fixed;left: 0;bottom: 0;width: 100%; visibility:hidden;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="copyright__inner">
                    <div class="cpy__right--left">
                        <p style="color: #000;">&#64; All Right Reserved to Ankapur and powered by <a href="" style="color: #000;">HyperApps</a></p>
                    </div>
                    <div style="text-align: justify;">
                        <p style="color: #000;"><i class="fa fa-mobile" style="margin-right: 5px;" aria-hidden="true"></i>Restaurant&nbsp;:&nbsp;+91 72072 65599</p>
                        <p style="color: #000;"><i class="fa fa-mobile" style="margin-right: 5px;" aria-hidden="true"></i>Support&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;+91 8885026686</p>
                    </div>
                    <div class="cpy__right--right">
                        <a href="#">
                            <img src="../../../../assets/images/icon/shape/2.png" alt="payment images">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>