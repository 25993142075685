import { Component, OnInit } from '@angular/core';
import { FooterComponent } from "../../theme/components/footer/footer.component";
import { HeaderComponent } from "../../theme/components/header/header.component";
import { SharedService } from '../../core/services/shared.service';
import { ApiService } from '../../core/services/api.service';
import { CommonModule } from '@angular/common';
import { WebSocketService } from '../../../app/core/services/websocket.service';
import { IframeComponentComponent } from "./iframe-component/iframe-component.component";
import { Subscription } from 'rxjs';
import { SafePipe } from "../../core/pipes/safe.pipe";
@Component({
    selector: 'app-order-tracking',
    standalone: true,
    templateUrl: './order-tracking.component.html',
    styleUrl: './order-tracking.component.scss',
    imports: [FooterComponent, HeaderComponent, CommonModule, IframeComponentComponent, SafePipe]
})
export class OrderTrackingComponent implements OnInit {
  currentOrder: any;
  orderStatus: any;
  private wsSubscription!: Subscription;
  constructor(public sharedData: SharedService, public apiService: ApiService,private wsService: WebSocketService) { }

  currentPage: string = "order-tracking";
  

  async ngOnInit(): Promise<void> {

    const localCurrentOrder: any = localStorage.getItem("currentOrder");
    this.currentOrder = JSON.parse(localCurrentOrder)

    console.log(this.currentOrder);
    localStorage.removeItem("foodBasket");
    await this.fetchOrderStatus();
    this.wsSubscription = this.wsService.getOrderStatusUpdates().subscribe((webSocketResponse: any) => {
      console.log(JSON.stringify(webSocketResponse))
      this.orderStatus = webSocketResponse;
    });
  }

  ngOnDestroy(): void {
    if (this.wsSubscription) {
      this.wsSubscription.unsubscribe();
    }
  }

  async fetchOrderStatus(): Promise<void> {
    this.apiService.getMethod(`/order/${this.currentOrder.data[0].id}`).subscribe({
      next: (response) => {
        console.log('Initial order status:', response);
        this.orderStatus = response.data[0];
      },
      error: (error) => {
        console.error('Error fetching order status:', error);
      }
    });
  }


  isOrderStatusReached(status: string): boolean {
    const statusOrder = [
      { status: 'CONFIRMED', state: 'CONFIRMED' },
      { status: 'PAYMENT_PENDING', state: 'CONFIRMED' },
      { status: 'PAID', state: 'PAID' },
      { status: 'ACCEPTED', state: 'ACCEPTED' },
      { status: 'MARK_FOOD_READY', state: 'ACCEPTED' },
      { status: 'OUT_FOR_PICKUP', state: 'ACCEPTED' },
      { status: 'REACHED_PICKUP', state: 'ACCEPTED' },
      { status: 'PICKED_UP', state: 'PICKED_UP' },
      { status: 'OUT_FOR_DELIVERY', state: 'OUT_FOR_DELIVERY' },
      { status: 'REACHED_DELIVERY', state: 'OUT_FOR_DELIVERY' },
      { status: 'DELIVERED', state: 'DELIVERED' }
    ];
    // console.log(JSON.stringify(this.orderStatus))
    const currentState = statusOrder.find(item => item.status === this.orderStatus?.status);
    const targetState = statusOrder.find(item => item.status === status);

    if (!currentState || !targetState) {
      return false;
    }

    const currentStateIndex = statusOrder.indexOf(currentState);
    const targetStateIndex = statusOrder.indexOf(targetState);
    return targetStateIndex <= currentStateIndex;
  }

  navigateMap(){
   console.log('trackingurl',this.orderStatus.deliveryTrackingLink);
   
    window.open(this.orderStatus.deliveryTrackingLink, "_blank");
  }
}
