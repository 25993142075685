<!-- <app-delivery-not-available></app-delivery-not-available> -->
@if(!workingHours){
<app-delivery-not-available></app-delivery-not-available>
} @if (mainLoading) {
<app-loader></app-loader>} @if(showDeliveryMode){
<app-deliverymode (closeDelivery)="closeDeliveryMode($event)"></app-deliverymode>
} @else if(!showDeliveryMode){

<app-header [pageName]="currentPage" (filterVeg)="getVegFilter($event)" (filterItem)="getFilterItem($event)" (branchId)="getBranchId($event)" ></app-header>

<div class="main-window mt-8 mb-lg-14 mb-8">
    <div class="container">
        <div class="row">
            @if (!searchActive) {
            <div class="col-lg-3 col-md-4 mb-6 mb-md-0 b-r hideXs">
                <div class="food__sidebar" style="position: sticky;top: 180px;">
                    <!-- <div class="food__search mt--30">
                                <h4 class="side__title">Search</h4>
                                <div class="serch__box">
                                    <input type="text" placeholder="Menu Search" [(ngModel)]="searchKeyword" (input)="categoryFilter();" style="    height: 40px;
                                    line-height: 40px;">
                                    <a (click)="categoryFilter();"><i class="fa fa-search"></i></a>
                                </div>
                            </div> -->
                    <div class="food__category__area mt--30 sideMenuStyle">
                        <h4 class="side__title">Menu
                            <i class="fa fa-bars menuBar" style="float: right;font-size:15px; margin-top: 6px;"></i>
                        </h4>

                        <ul class="food__category" style="scrollbar-width: none;">
                            <!-- $index == followOnIndex -->
                            @for (category of menuResponseFiltered; track $index) {
                            <li [ngClass]="{ 'selected-menu-list': $index == followOnIndex }" (click)="selectCategory(category, $index);">
                                <a style="color: #020101 !important;">{{category!.categoryName |
                                    emptyStringCheck:'Category'}}
                                    <!-- <span>({{category.items.length}})</span> -->
                                </a>
                            </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
            }


            <div class="col-lg-9 col-md-12">
                @if (!searchActive){ @if (showMenu) {
                <i class="fa fa-times menuBar" style="float: right;font-size:15px; margin-top: 6px; " (click)="showMenu=!showMenu"> Hide</i> } @else {
                <i class="fa fa-bars menuBar" style="float: right;font-size:15px; margin-top: 6px;" (click)="showMenu=!showMenu"> Menu</i> }


                <div id="sidebar" class="showXs" [ngClass]="{ 'active': showMenu == false}">
                    <div class="list">
                        <div class="food__sidebar" style="padding: 0 20px;">
                            <!-- <div class="food__search mt--30">
                                        <h4 class="side__title">Search</h4>
                                        <div class="serch__box">
                                            <input type="text" placeholder="Menu Search" [(ngModel)]="searchKeyword" (input)="categoryFilter();" style="    height: 40px;
                                            line-height: 40px;">
                                            <a (click)="categoryFilter();"><i class="fa fa-search"></i></a>
                                        </div>
                                    </div> -->
                            <div class="food__category__area mt--30" style="margin-top:18px;">
                                <h4 class="side__title" style="color: #fff;">Categories
                                </h4>

                                <ul class="food__category">
                                    @for (category of menuResponseFiltered; track $index) {
                                    <li [ngClass]="{ 'selected-menu-list': $index == followOnIndex }" (click)="selectCategory(category, $index); showMenu=!showMenu">
                                        <a style="color:#fff;">{{category!.categoryName |
                                            emptyStringCheck:'Category'}}
                                            <!-- <span>({{category.items.length}})</span> -->
                                        </a></li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <!-- [ngClass]="{'searchActive': searchActive == true}" -->


                <div class="product-wrapper">
                    @if (!searchActive){
                    <div class="food__search mt--30">
                        <h4 class="side__title">
                            <!-- <span class="deliveryMode">
                                <ul class="checkout-method-list"> -->
                            <!-- <li [ngClass]="{ 'active': deliveryMode == 1 }" (click)="deliveryMode=1;"
                                        class="active" data-form="checkout-login-form">Delivery</li> -->
                            <!-- <li [ngClass]="{ 'active': deliveryMode == 2 }" (click)="deliveryMode=2;"
                                                    data-form="checkout-register-form">Pickup</li> -->
                            <!-- </ul>
                            </span> -->
                            <div class="location-wrapper hideXs">
                                <span class="service-category"><i class="fa fa-map-marker"
                                        style="color: #d50c0d;padding-right:10px;font-size: 24px;;"
                                        aria-hidden="true"></i>Location
                                    : </span>
                                <span class="address cls-ellipsis">{{address}}</span>
                                <div class="grid__show__btn grid__show__btn11">
                                    <a class="food__btn change-btn" (click)="addrSearch = true">Change</a>
                                </div>
                            </div>


                            <div class="location-wrapper showXs" style="margin-top:15px;">
                                <div class="row">

                                    <span class="address" style="display: inline-flex;"><i class="fa fa-map-marker" style="color: #d50c0d;font-size: 24px;"
                                        aria-hidden="true"></i>{{address}}</span>
                                    <div class="grid__show__btn" style="margin-top: 20px;"><a class="food__btn change-btn">Change</a></div>

                                </div>



                            </div>
                        </h4>
                    </div>
                    } @if (noItemFound) {
                    <span class="no-found">Sorry No Items found..</span> } @if (!noItemFound && searchActive){
                    <div class="food__search mt--30">
                        <h4 class="side__title">
                            <!-- {{filteredCategory | titlecase}} -->
                            Search Results
                        </h4>
                    </div>
                    }


                    <div class="row m-b-60">
                        @for (item of selectedCategory.items; track $index) {
                        <div class="col-md-6">
                            <div class="food__menu__container">
                                <div class="food__menu__inner d-flex flex-wrap flex-md-nowrap flex-lg-nowrap">


                                    <!-- @if (item.addon.length >0 || item.variation.length >0) {
                                        <div class="customize_btn"><a class="">Customizable</a>
                                        </div>
                                        } -->
                                    <div class="food__menu__details">
                                        <div class="food__menu__content" [ngClass]="{ 'out-of-stock': item.active === '0', 'in-stock': item.active === '1' }">
                                            <h2>{{item.itemName | removeSpecialCharacter}}</h2>

                                            <ul class="food__dtl__prize rating">
                                                <li class="textPrice">₹ {{item.price | showPriceVariation:'menu' }}
                                                </li>
                                                @if(item.itemDescription){
                                                <div class="description-text-wrapper">
                                                    <p class="description-text" [ngClass]="{ 'desScroll': item.readMore == 1 }">
                                                        {{item.itemDescription}}
                                                    </p>
                                                    @if(!item.readMore) {
                                                    <span (click)="item.readMore=1">Read
                                                            more...</span> }
                                                </div>
                                                }
                                                <!-- @if (seletedItemId.includes(item.id) && item.active === '1') {
                                                <li style="font-size: 16px;    text-align: right;">
                                                    <div class="product-quantity" style="display: inline-block;">
                                                        <div class="cart-plus-minus">
                                                            <div class="dec qtybutton" (click)="sameAddonConfirmation(item, $index,'reduce')">-
                                                            </div>
                                                            <input class="cart-plus-minus-box" type="text" name="qtybutton" value="{{item | displayQuantity}}" readonly="true">
                                                            <div class="inc qtybutton" (click)="sameAddonConfirmation(item, $index,'add')">+
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                } -->
                                            </ul>

                                        </div>
                                    </div>



                                    @if(item.itemImageUrl != ""){
                                    <div class="food__menu__thumb">
                                        <img [src]="item.itemImageUrl" alt=""> @if(!seletedItemId.includes(item.id)&&item.active === '1'){
                                        <li style="text-align: right;">
                                            <div class="add__to__cart__btn" style="display: inline-block;">
                                                <a class="food__btn" (click)="selectItem(item)">Add</a>
                                            </div>
                                        </li>
                                        } @if (seletedItemId.includes(item.id) && item.active === '1') {
                                        <li style="font-size: 16px; text-align: right;list-style: none;margin-top: 7px;">
                                            <div class="product-quantity" style="display: inline-block;">
                                                <div class="cart-plus-minus">
                                                    <div class="dec qtybutton" (click)="sameAddonConfirmation(item, $index,'reduce')">-
                                                    </div>
                                                    <input class="cart-plus-minus-box" type="text" name="qtybutton" value="{{item | displayQuantity}}" readonly="true">
                                                    <div class="inc qtybutton" (click)="sameAddonConfirmation(item, $index,'add')">+
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        } @if(item.active === '0'){
                                        <li style="text-align: right; color: red;">
                                            Out of Stock
                                        </li>
                                        } @if (item.addon.length >0 || item.variation.length >0){
                                        <span class="customize_txt" [ngClass]="{ 'top-0': seletedItemId.includes(item.id) }">Customisable</span> }
                                    </div>
                                    } @if(item.itemImageUrl == ""){
                                    <div class="food__menu__thumb flex-30">

                                        @if(!seletedItemId.includes(item.id)&&item.active === '1'){
                                        <li style="text-align: right;list-style: none">
                                            <div class="add__to__cart__btn" style="display: inline-block;">
                                                <a class="food__btn top-0" (click)="selectItem(item)">Add</a>
                                            </div>
                                        </li>
                                        } @if (seletedItemId.includes(item.id) && item.active === '1') {
                                        <li style="font-size: 16px; text-align: right;list-style: none;margin-top: 7px;">
                                            <div class="product-quantity" style="display: inline-block;">
                                                <div class="cart-plus-minus">
                                                    <div class="dec qtybutton" (click)="sameAddonConfirmation(item, $index,'reduce')">-
                                                    </div>
                                                    <input class="cart-plus-minus-box" type="text" name="qtybutton" value="{{item | displayQuantity}}" readonly="true">
                                                    <div class="inc qtybutton" (click)="sameAddonConfirmation(item, $index,'add')">+
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        } @if(item.active === '0'){
                                        <li style="text-align: right; color: red;">
                                            Out of Stock
                                        </li>
                                        } @if (item.addon.length >0 || item.variation.length >0){
                                        <span class="customize_txt top-0">Customisable</span> }
                                    </div>
                                    }


                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>






                <div class="product-wrapper " style="background-color: #e9ecef;border-radius: 12px; ">

                    <div class="food__search mt--30 " style="padding: 30px 30px; ">


                        <div class="product-wrapper " style="background-color: #e9ecef;border-radius: 12px; ">
                            <div class="food__search mt--30 " style="padding: 30px 30px; ">

                                <h4 class="side__title ">Ankapur</h4>
                                <div class="deliveryMode ">
                                    <div class="service-category " style="color: #4d4d4d; ">
                                        <i aria-hidden="true " class="fa fa-map-marker " style="color: #d50c0d;padding-right: 10px; "></i>
                                        <span class="address "> {{branchData?.address}} </span><br>
                                        <i aria-hidden="true " class="fa fa-mobile " style="color: #d50c0d;padding-right: 10px; "></i>
                                        <span class="address "> +91 {{branchData?.contact}}</span><br>
                                        <!-- <span class="address ">Fssai- 13623013000801</span><br><br> -->
                                        <div class="address ">
                                            <a href="https://hyperapps.in "> <img src="../../../assets/images/logo/hyperapps-horizontal.png " style="max-width: 250px; "></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--
                @for (followOn of followOnCategory; track $index) { @if(followOn?.items && followOn?.items.length>0){
                <div class="product-wrapper ">
                    <div class="food__search mt--30 ">
                        <h4 class="side__title "> {{followOn!.categoryName | titlecase}}</h4>
                    </div>

                    <div class="row m-b-60 ">
                        @for (item of followOn.items; track $index) {
                        <div class="col-md-6 ">
                            <div class="food__menu__container ">
                                <div class="food__menu__inner d-flex flex-wrap flex-md-nowrap flex-lg-nowrap ">
                                    @if(item.itemImageUrl != " "){
                                    <div class="food__menu__thumb ">
                                        <img [src]="item.itemImageUrl " alt=" ">
                                    </div>

                                    }

                                    <div class="food__menu__details ">
                                        <div class="food__menu__content ">
                                            <h2>{{item.itemName | removeSpecialCharacter}}</h2>
                                            @if (item.addon.length >0 || item.variation.length >0){
                                            <span class="customize_txt ">Customizable</span> }
                                            <ul class="food__dtl__prize rating ">
                                                <li class="textPrice ">₹ {{item.price | showPriceVariation:'menu'}}
                                                </li>
                                                @if (seletedItemId.includes(item.id)) {
                                                <li style="font-size: 16px; text-align: right; ">
                                                    <div class="product-quantity " style="display: inline-block; ">
                                                        <div class="cart-plus-minus ">
                                                            <div class="dec qtybutton "
                                                                (click)="sameAddonConfirmation(item, $index, 'reduce') ">-
                                                            </div>
                                                            <input class="cart-plus-minus-box " type="text "
                                                                name="qtybutton " value="{{item | displayQuantity}} "
                                                                readonly="true ">
                                                            <div class="inc qtybutton "
                                                                (click)="sameAddonConfirmation(item, $index, 'add') ">+
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                } @else {
                                                <li style="text-align: right; ">
                                                    <div class="add__to__cart__btn " style="display: inline-block; ">
                                                        <a class="food__btn " (click)="selectItem(item) ">Add</a>
                                                    </div>
                                                </li>
                                                }
                                            </ul>
                                            @if(item.itemDescription){
                                            <div class="description-text-wrapper ">
                                                <p class="description-text "
                                                    [ngClass]="{ 'desScroll': item.readMore==1 } ">
                                                    {{item.itemDescription}}
                                                </p>
                                                @if(!item.readMore) {
                                                <span (click)="item.readMore=1 ">Read
                                                    more...</span> }
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                } } -->
        @if(this.foodBasket.length >= 0){
        <div class="container viewCart ">
            <div class="row ">
                <div class="col-lg-12 col-md-12 col-sm-12 ">
                    <div class="grid__show__btn left ">
                        <a class="food__btn " (click)="showFoodPagecart() ">
                            <i class="zmdi zmdi-shopping-basket "></i> ₹ {{cartItemPrice | number : '1.2-2'}}
                            <i class="fa fa-angle-up "></i>
                        </a>
                    </div>
                    <!-- <div class="food-page-cart-show " (click)="showFoodPagecart() ">
                                <i class="fa fa-angle-up "></i>
                            </div> -->
                    @if(this.foodBasket.length > 0){
                    <div class="grid__show__btn right ">
                        <a class="food__btn " routerLink="/cart" href=" ">View Cart</a>
                    </div>
                    }
                </div>
            </div>
        </div>
        }
    </div>
</div>
}

<!-- POpup -->
@if (showAddonVariationDialig) {
<app-addonpopup [variations]="variationResponse " [addOnChoice]="addonResponse " [menuItem]="selectedItem.itemName " (addedItem)="getAddedItem($event) "></app-addonpopup>
} @if (sameAddon) {
<div class="accountbox-wrapper is-visible ">
    <div class="body-overlay "></div>
    <div class="accountbox text-left " style="padding: 30px; ">
        <span class="close-icon " title="Close " (click)="sameAddon=! sameAddon ">
            <i class="zmdi zmdi-close "></i>
        </span>
        <ul class="nav accountbox__filters " style="border-bottom: solid 1px #c2c2c2; ">
            <li style="text-align: center;width: 100%;padding: 0px; " class="section__title title__style--2 ">
                <div class="food__menu__content ">
                    <h2 style="font-size:20px;color: #d50c0d;padding-bottom: 10px; ">The selected item has Variation / Addon. Do you want to select the same or New </h2>
                </div>
            </li>
        </ul>
        <hr>
        <div class="accountbox__inner tab-content ">
            <div class="accountbox__login tab-pane fade show active " style="width: 100%; ">
                <div class="single-input " style="text-align: center; ">
                    <button (click)="addItemQuantity( 'same') " type="button " class="food__btn " style="width: 30%;margin-right: 10px;font-size: 15px;padding: 0px; "><span>Use
                            Same</span></button>
                    <button (click)="addItemwithNewAddon() " type="button " class="food__btn " style="width: 30%;font-size: 15px;padding: 0px; "><span>Select New</span></button>
                </div>
            </div>
        </div>
    </div>
</div>
} @if (Showfoodcart) {
<div class="accountbox-wrapper foodcartpopup is-visible ">
    <div class="body-overlay "></div>
    <div class="accountbox text-left ">
        <span class="close-icon " title="Close " (click)="Showfoodcart=! Showfoodcart ">
            <i class="zmdi zmdi-close "></i>
        </span>
        <ul class="nav accountbox__filters " style="border-bottom: solid 1px #c2c2c2; ">
            <li style="text-align: center;width: 100%;padding: 0px; " class="section__title title__style--2 ">
                <div class="food__menu__content ">
                    <h5 style="font-size:16px;color: #d50c0d;padding-bottom: 10px; ">Cart Items </h5>
                </div>
                <div class="row list-wrapper ">
                    @for (basketitem of foodBasket; track $index) {
                    <div class="col-md-6 col-sm-12 col-xl-12 ">
                        <div class="food__menu__container ">
                            <div class="food__menu__inner d-flex flex-wrap flex-md-nowrap flex-lg-nowrap ">
                                <!-- <div class="food__menu__thumb ">
                                     @if(basketitem.item.addon.length >0 || basketitem.item.variation.length >0) {
                                    <div class="grid__show__btn "><a class="food__btn ">Customizable</a></div>
                                    }
                                </div> -->
                                <div class="food__menu__details ">
                                    <div class="food__menu__content ">
                                        <h2>{{basketitem.item.itemName | removeSpecialCharacter}}</h2>
                                        @if(basketitem.item.addon.length >0 || basketitem.item.variation.length >0) {
                                        <span class="cust-span ">Customizable</span>
                                        <!-- <div class="grid__show__btn "><a class="food__btn "></a></div> -->
                                        }
                                        <ul class="food__dtl__prize rating ">
                                            <li>₹ {{basketitem | showPriceVariation:''}}</li>
                                            <!-- <li>₹ {{basketitem.item.price}}</li> -->
                                            <li class="li-rigth ">
                                                <div class="product-quantity " style="display: inline-block; ">
                                                    <div class="cart-plus-minus ">
                                                        <div class="dec qtybutton " (click)="addFromCart(basketitem.item, $index, 'reduce') ">
                                                            <!-- <div class="dec qtybutton " (click)="sameAddonConfirmation(basketitem.item, $index, 'reduce') "> -->
                                                            -
                                                        </div>
                                                        <input class="cart-plus-minus-box " type="text " name="qtybutton " value="{{basketitem.item.quantity}} " readonly="true ">
                                                        <div class="inc qtybutton " (click)="addFromCart(basketitem.item, $index, 'add') ">
                                                            <!-- <div class="inc qtybutton " (click)="sameAddonConfirmation(basketitem.item, $index, 'add') "> -->
                                                            +
                                                        </div>
                                                    </div>
                                                </div>


                                            </li>
                                            @if (basketitem.item.addon.length >0 || basketitem.item.variation.length >0) { @if(basketitem?.addonVariation?.varients != undefined){
                                            <li class="addon-list ">Varient: {{basketitem.addonVariation.varients.name}}
                                            </li>
                                            }
                                            <li class="addon-list ">
                                                AddOns: @for (item of basketitem?.addonVariation?.addOnNames; track $index; let last = $last) {
                                                <span>{{item}}</span> @if (!last) {<span>, </span>} } @for (item of basketitem?.addonVariation?.VatiationAddOnName; track $index; let last = $last) {
                                                <span>{{item}}</span> @if (!last) {<span>, </span>} }
                                            </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </li>
        </ul>

    </div>
</div>
} @if (addrSearch) {
<div class="accountbox-wrapper is-visible ">
    <div class="body-overlay "></div>
    <div class="accountbox text-left " style="padding: 30px;padding-bottom: 50px; ">
        <span class="close-icon " title="Close " (click)="addrSearch=! addrSearch ">
            <i class="zmdi zmdi-close "></i>
        </span>
        <ul class="nav accountbox__filters " style="border-bottom: solid 1px #c2c2c2; ">
            <li style="text-align: center;width: 100%;padding: 0px; " class="section__title title__style--2 ">
                <div class="food__menu__content ">
                    <h2 style="font-size:20px;color: #d50c0d;padding-bottom: 10px; ">Address Search </h2>
                </div>
            </li>
        </ul>
        <hr> @if (unServiceableValue) {
        <span style="font-size:12px;color: #d50c0d; ">{{errorMessage}}</span> }
        <!-- <div class="accountbox__inner tab-content ">
            <div class="accountbox__login tab-pane fade show active " style="width: 100%; ">
                <div class="single-input "> -->

        <div class="dropdown ">
            <div id="myDropdown " class="dropdown-content ">
                <input type="text " placeholder="Search street, locality " [(ngModel)]="searchTerm " (input)="search() ">
                <i class="fa fa-search "></i>
                <a *ngIf="showResults " style="height: 60vh;overflow-y: auto; ">
                    <div class="result " *ngFor="let result of searchResults " (click)="selectResult(result) " style="cursor: pointer; ">
                        {{ result.text }}
                    </div>
                </a>
            </div>
            <!-- </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
}
<app-store-closed *ngIf="!restaurentActive "></app-store-closed>

<app-footer></app-footer>
