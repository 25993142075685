import { Component, OnInit, HostListener, Injectable, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HeaderComponent } from "../../theme/components/header/header.component";
import { FooterComponent } from "../../theme/components/footer/footer.component";

import { ApiService } from "../../core/services/api.service";
import { EmptyStringCheckPipe } from '../../core/pipes/empty-string-check.pipe';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { RemoveSpecialCharacterPipe } from '../../core/pipes/remove-special-character.pipe';

import { Observable, Subscription, catchError, debounceTime, distinctUntilChanged, map, of, share, switchMap, timer } from 'rxjs';

import { AddonpopupComponent } from "./addonpopup/addonpopup.component";
import { SharedService } from '../../core/services/shared.service';
import { DeliverymodeComponent } from "./deliverymode/deliverymode.component";
import { SearchBarComponent } from './search-bar/search-bar.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { ItemCartService } from '../../core/services/item-cart.service';
import { DisplayQuantityPipe } from "../../core/pipes/display-quantity.pipe";
import { Config } from '../../core/config';
import { ShowPriceVariationPipe } from "../../core/pipes/show-price-variation.pipe";
import { WebSocketService } from '../../../app/core/services/websocket.service';
import { StoreClosedComponent } from "../../store-closed/store-closed.component";

import { DeliveryNotAvailableComponent } from "../delivery-not-available/delivery-not-available.component";
import { LoaderComponent } from "../../theme/components/loader/loader.component"
import moment from 'moment';

interface LocationData {
    id: string;
    formattedAddress: string;
    location: {
        latitude: number;
        longitude: number;
    };
}

@Injectable({
    providedIn: 'root'
})
@Component({
    selector: 'app-food-menu',
    standalone: true,
    templateUrl: './food-menu.component.html',
    styleUrl: './food-menu.component.scss',
    imports: [
        HeaderComponent, FooterComponent,
        EmptyStringCheckPipe,
        RemoveSpecialCharacterPipe,
        CommonModule,
        TooltipModule,
        AddonpopupComponent,
        DeliverymodeComponent,
        SearchBarComponent,
        LoginComponent,
        FormsModule,
        RouterLink,
        DisplayQuantityPipe,
        ShowPriceVariationPipe,
        StoreClosedComponent,
        DeliveryNotAvailableComponent,
        LoaderComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})

export class FoodMenuComponent implements OnInit {

    currentPage: string = "food-menu"
    menuResponse: any = [];
    menuResponseFiltered: any = [];
    selectedCategory: any = {};
    followOnCategory: any = [];
    addedItem: Array<string> = [];
    selectedItem: any = {};
    seletedItemId: any = [];
    addonResponse: any = [];
    variationResponse: any = [];
    showAddonVariationDialig: boolean = false;
    foodBasket: any = [];
    showDeliveryMode: boolean = false;
    showLoginForm: boolean = true;
    address: string = '';
    searchKeyword: string = '';
    deliveryMode: number = 1;
    sameAddon: boolean = false;
    cartItemPrice: number = 0;
    showMenu: boolean = false;
    followOnIndex: number = 0;
    mobile: number = 0;
    vegFilter: number = 0;
    addItemQunatityIndex!: number;
    selectedItemWithAddon: any = {};
    Showfoodcart: boolean = false;
    initialIndex: number | undefined;
    indexOfSameItemWithAddons: any = [];

    addrSearch: boolean = false;
    unServiceableValue: boolean = false;
    errorMessage!: string;
    searchTerm: string = '';
    searchPlaceId: string = '';
    searchResults: { placeId: string, text: string }[] = [];
    showResults: boolean = false;
    restaurentId: number | undefined;
    // restaurentId: number = Number(Config.rest_id);
    vegFilterOn: boolean = false;
    searchActive: boolean = false;
    noItemFound: boolean = false;
    private wsSubscription!: Subscription;
    restaurentActive: boolean = true;
    workingHours: boolean = true;
    filteredCategory: string = '';

    rxTime = new Date();
    mainLoading: boolean = false;
    branchData: any | undefined;

    constructor(
        public apiService: ApiService,
        public sharedData: SharedService,
        public addItemService: ItemCartService,
        private wsService: WebSocketService,
    ) { }

    ngOnInit(): void {
        // this.sharedData.getbranchData().subscribe((data:any)=>{
        //     console.log('brancgdata',data);

        //   })
        //     window.addEventListener('storage', (event: StorageEvent) => {
        //     if (event.key === 'selectedRestId') {
        let restId: any = localStorage.getItem("selectedRestId")
        this.restaurentId = parseInt(restId);
        console.log('selectedRestId', this.restaurentId, restId);
        //     }

        //     });

        this.wsSubscription = this.wsService.getRestaurantStatusUpdates().subscribe((webSocketResponse: any) => {
            this.restaurentActive = webSocketResponse.store_status == 0 ? false : true;
        });

        this.wsSubscription = this.wsService.getItemStatusUpdates().subscribe((webSocketResponse: any) => {
            this.updateItemStock(webSocketResponse);
        });

        let categoryResponse: any = [];
        const localstrfoodItem: any = localStorage.getItem("foodBasket");
        if (localstrfoodItem != null) {
            this.foodBasket = JSON.parse(localstrfoodItem);
            this.foodBasket.forEach((ele: any) => { this.seletedItemId.push(ele.item.id) });
            this.calculateCartPrice();
        }
        // console.log(this.foodBasket);

        // Service call to get menu details
        if (this.restaurentId != undefined && !isNaN(this.restaurentId)) {
            this.apiService.getMethod("/menu/category?restaurantId=" + this.restaurentId).subscribe({
                next: (reponse) => {
                    this.menuResponse = reponse.data;
                    this.menuResponseFiltered = reponse.data;
                    this.menuResponseFiltered.forEach((obj: any) => {
                        obj.allItems = obj.items;
                    });
                    console.log(this.menuResponse);
                    this.selectCategory(this.menuResponse[0], 0);
                    localStorage.setItem("menu", JSON.stringify(this.menuResponse));
                    // this.sharedData.sendMenuData(this.menuResponse)
                    this.mainLoading = false;
                },
                error: (error) => { console.log(error); }

            });
            if (!isNaN(this.restaurentId)) {
                this.checkWorkingHours();
                this.loadAddress();

                const vendorDetail: any = localStorage.getItem('vendorData');
                let vdata = JSON.parse(vendorDetail)
                console.log(vdata);
                if (vdata.restaurantDetails != undefined) {
                    vdata.restaurantDetails.forEach((brdata: any) => {
                        if (brdata.id == this.restaurentId) {
                            this.branchData = brdata;
                        }
                    });

                }
                console.log(' this.workingHours', this.workingHours, this.restaurentId, this.branchData);
            }

            
        }


        // Service call to get category details
        // this.apiService.getMethod("/category").subscribe({
        //     next: (reponse) => {
        //         categoryResponse = reponse.data;
        //         console.log('categoryResponse', categoryResponse);
        //     },
        //     error: (error) => { console.log(error) }
        // });

        // Service call to get Addon details 
        // this.apiService.getMethod("/addon/10722").subscribe({
        //     next: (reponse) => {
        //         reponse.data = sampledata.data;
        //         console.log('addonResponse', reponse.data);
        //     },
        //     error: (error) => { console.log(error) }
        // });

        // Service call to get variations details ["10523229","10523230"]
        
        this.sharedData.getvegFilterFunction().subscribe((data) => {
            if (data == false) {
                this.menuResponseFiltered.forEach((obj: any) => {
                    obj.items = obj.allItems.filter((obj1: any) => obj1.itemAttributeId == 1);
                });
            } else {
                this.menuResponseFiltered.forEach((obj: any) => {
                    obj.items = obj.allItems;
                });
            }
            console.log(this.selectedCategory.allItems);
            console.log(this.selectedCategory.items);
        });



    }

    checkWorkingHours() {
        // this.workingHours = this.sharedData.checkWorkingHours();

        this.apiService.getMethod(`/restaurant/${this.restaurentId}`).subscribe({
            next: (response) => {
                localStorage.setItem('restaurantDetails', JSON.stringify(response.data[0]));
                const restaurantDetails: any = response.data[0];
                this.restaurentActive = restaurantDetails.active;
                const workingHoursData = restaurantDetails.deliveryHours;
                const format = 'H:mm';
                const currentTime = moment().format('H:mm');
                const fromTime1 = moment(workingHoursData[0].from, format);
                const fromTime = moment(workingHoursData[1].from, format);
                const toTime1 = moment(workingHoursData[0].to, format);
                const toTime = moment(workingHoursData[1].to, format);
                const now = moment(currentTime, format);
                if (now.isBetween(fromTime1, toTime1) || now.isBetween(fromTime, toTime)) {
                    this.workingHours = true;
                } else {
                    this.workingHours = false;
                }
            },
            error: (error) => { console.error('Error fetching restaurant Details:', error); }
        });
    }

    updateItemStock(itemUpdateMessage: any): void {
        console.log(JSON.stringify(itemUpdateMessage))
        const { itemID, inStock } = itemUpdateMessage;
        const activeStatus = inStock ? '1' : '0';
        this.selectedCategory.items.forEach((item: { id: any; active: string; }) => {
            if (itemID.includes(item.id)) {
                item.active = activeStatus;
            }
        });
    }


    ngOnDestroy(): void {
        if (this.wsSubscription) {
            this.wsSubscription.unsubscribe();
        }
    }

    loadAddress() {
        const selectedLocation = localStorage.getItem('selectedLocation');
        this.showDeliveryMode = !selectedLocation;
        if (selectedLocation) {
            const locationData = JSON.parse(selectedLocation);
            this.address = locationData.formattedAddress;
            console.log(' this.address' ,selectedLocation);
            
        }
    }


    /**
     * To select the food category menu
     * @param selected : Data of selected menu category
     * @param index : Index of that respective selected array value
     */
    selectCategory(selected: Object, index: number): void {
        console.log(selected, index);
        this.selectedCategory = JSON.parse(JSON.stringify(selected));
        this.selectedCategory.allItems = JSON.parse(JSON.stringify(this.selectedCategory.items));
        this.followOnIndex = index;
        this.initialIndex = index;

        this.followOnCategory = [];
    }

    /**
     * To add item into foodBasket array on clicking Add button 
     * If there is no variation / Addon it will be added else it will call respective API
     * @param item : Selected Item value
     */
    selectItem(item: any): void {

        console.log(item);
        this.addItemQunatityIndex = -1;
        this.selectedItemWithAddon = {};
        if (item.quantity == undefined) {
            item['quantity'] = 1;
        }
        // else {
        //     item.quantity += 1;
        // }
        this.selectedItem = item;
        if (item.addon.length == 0 && item.variation.length == 0) {
            this.getAddedItem({ action: "add" });
        }
        if (item.addon.length) {
            this.getAddonItems(item.addon);
        }
        if (item.variation.length) {
            this.getVariations(item.variation);
        }
    }

    /**
     * Method to get the variations values with API 
     * @param variation : Array of variation - Ids
     */
    getVariations(variation: any) {
        this.variationResponse = [];
        variation.map((ele: any) => {
            this.apiService.getMethod(`/variation/${ele}/addons`).subscribe({
                next: (reponse) => {
                    this.variationResponse.push(reponse.data);
                    if (this.variationResponse.length == this.selectedItem.variation.length)
                        this.showAddonVariationDialig = true;
                    console.log("variationResponse:", this.variationResponse);

                },
                error: (error) => { console.log(error) }
            });
        });
    }

    /**
     * Method to get the addon values with API 
     * @param addOns : Array of addon - Ids
     */
    getAddonItems(addOns: any) {
        this.addonResponse = [];
        addOns.map((ele: any) => {
            this.apiService.getMethod(`/addon-group/${ele}/items`).subscribe({
                next: (reponse) => {
                    this.addonResponse.push(reponse.data);
                    this.showAddonVariationDialig = true;
                    console.log("addonResponse:", this.addonResponse);

                },
                error: (error) => { console.log(error) }
            });
        });
    }

    /**
     * Method invoked after addon popup component emits value
     * @param event : Data from the add-on popup component
     */
    getAddedItem(event: any) {
        this.showAddonVariationDialig = false;
        console.log('event', event, this.selectedItem);
        console.log(this.foodBasket, this.addItemQunatityIndex);
        // this.foodBasket
        if (event.action == "add") {
            if (this.indexOfSameItemWithAddons.length > 0) {
                this.indexOfSameItemWithAddons.forEach((indexval: number) => {
                    if ((this.sameAddonItems(event.addonVariation.addOnNames, this.foodBasket[indexval].addonVariation.addOnNames))) {
                        this.addItemQunatityIndex = indexval;
                    }
                });
            }

            if (this.addItemQunatityIndex >= 0 && (this.sameAddonItems(event.addonVariation.addOnNames, this.foodBasket[this.addItemQunatityIndex].addonVariation.addOnNames))) {
                if (this.foodBasket[this.addItemQunatityIndex].addonVariation.varients.id == event?.addonVariation.varients.id) {
                    this.addItemQuantity('');

                } else {
                    const addItem = {
                        categoryId: this.selectedCategory.id,
                        item: this.selectedItem,
                        addonVariation: event?.addonVariation
                    }
                    this.foodBasket.push(addItem);
                }

            } else {
                this.selectedItem.quantity = 1;
                const addItem = {
                    categoryId: this.selectedCategory.id,
                    item: this.selectedItem,
                    addonVariation: event?.addonVariation
                }
                this.foodBasket.push(addItem);
            }

            this.seletedItemId.push(this.selectedItem.id);
            // this.calculateCartPrice();
            // console.log('this.seletedItemId', this.seletedItemId);


        }
        console.log(this.foodBasket);
        this.storefoodBasketData();

        this.addonResponse = [];
        this.variationResponse = [];
    }

    closeDeliveryMode(event: any) {
        this.showDeliveryMode = false;

        this.ngOnInit();
    }

    //Dummmy show
    toggleLoginForm() {
        this.showLoginForm = !this.showLoginForm;
    }

    toggleAddonForm() {
        this.showAddonVariationDialig = !this.showAddonVariationDialig;
    }

    categoryFilter() {
        this.menuResponseFiltered = this.menuResponse.filter((obj: any) => obj.categoryName.toLowerCase().indexOf(this.searchKeyword) > -1);
    }

    getFilterItem(event: any) {
        console.log(typeof event);
        if (event != "") {
            const filteredItems = this.menuResponse.reduce((acc: any, category: any) => {
                const matchingItems = category.items.filter((item: any) =>
                    item.itemName.toLowerCase().includes(event.toLowerCase())
                );
                return acc.concat(matchingItems);
            }, []);
            // this.menuResponseFiltered = filteredItems;
            filteredItems.length == 0 ? this.noItemFound = true : this.noItemFound = false;
            this.showMenu = false;
            this.searchActive = true;
            this.selectedCategory.items = JSON.parse(JSON.stringify(filteredItems));
            console.log(filteredItems.length, this.selectedCategory); //empty_plate
            const categoryObject = this.menuResponse.find((category: any) => {
                if (category && category.items) {
                    const matchingItems = category.items.filter((item: any) =>
                        item.itemName.toLowerCase().includes(this.selectedCategory.items[0].itemName.toLowerCase())
                    );
                    return matchingItems.length > 0;
                }
                return false;
            });
            (categoryObject) ? this.filteredCategory = categoryObject.categoryName : this.filteredCategory = '';
            console.log(this.filteredCategory);
        } else {
            // this.showMenu = true;
            this.searchActive = false;
            console.log(this.menuResponse);
            this.noItemFound = false;
            this.selectCategory(this.menuResponse[this.followOnIndex], this.followOnIndex);

        }
    }

    getVegFilter(event: any): void {
        console.log(event);
        this.vegFilterOn = event;
        if (event) {
            this.menuResponseFiltered.forEach((obj: any) => {
                obj.items = obj.allItems.filter((obj1: any) => obj1.itemAttributeId == 1);
            });
            // const filteredData = this.menuResponse.map((category: any) => {
            //     return {
            //         ...category,
            //         items: category.items.filter((item: any) => item.itemAttributeId === "1")
            //     };
            // }).filter((category: any) => category.items.length > 0);
            // console.log(filteredData);
            // this.showMenu = false;
            // this.selectedCategory.items = JSON.parse(JSON.stringify(filteredData));
        } else {
            this.menuResponseFiltered.forEach((obj: any) => {
                obj.items = obj.allItems;
            });

        }
    }

    getBranchId(event: any) {
        console.log(event, 'branckid');
        this.restaurentId = parseInt(event);
        this.ngOnInit();
        localStorage.removeItem('foodBasket');
        this.foodBasket = [];
        this.calculateCartPrice();
    }
    scroll(el: HTMLElement) {
        el.scrollIntoView();
    }

    // @HostListener("window:scroll", ["$event"])
    // onWindowScroll() {
    //     const windowScrollTop = document.documentElement.scrollTop;
    //     const windowHeight = window.innerHeight;
    //     const documentHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight);
    //     // console.log(windowScrollTop + windowHeight + 1 , documentHeight);

    //     if (windowScrollTop + windowHeight + 1 >= documentHeight) {
    //         this.followOnIndex = this.followOnIndex + 1;
    //         this.loadNext(this.followOnIndex);
    //     }

    // }

    // loadNext(index: number): void {
    //     this.followOnCategory.push(this.menuResponseFiltered[index]);
    //     console.log(this.menuResponseFiltered[index]);
    // }


    /**
     * Method invoked on clicking + / - buttons on UI
     * @param opteditem : Selected item value 
     * @param index :Index of selected item from the foodBasket array value
     * @param operation : To add or reduce quantity of the item
     */
    sameAddonConfirmation(opteditem: any, Itemindex: number, operation: string) {

        console.log('plusbtn', opteditem, Itemindex, operation, this.foodBasket);
        // console.log(this.foodBasket, this.cartItemPrice);
        this.indexOfSameItemWithAddons = [];

        if (opteditem.addon.length == 0 && opteditem.variation.length == 0) {
            this.foodBasket.forEach((itemEle: any, index: number) => {
                if (itemEle.item.id == opteditem.id) {
                    this.addItemQunatityIndex = index;
                }
            });
        }
        if (operation == 'add') {
            this.foodBasket.forEach((itemEle: any, index: number) => {
                if (itemEle.item.id == opteditem.id) {
                    if (opteditem.addon.length > 0 || opteditem.variation.length > 0) {
                        this.indexOfSameItemWithAddons.push(index);
                    } else {
                        this.addItemQunatityIndex = index;
                    }
                }
            });
            console.log(this.addItemQunatityIndex, Itemindex);

        }



        // this.selectedItemWithAddon = opteditem;

        if (operation == 'reduce') {
            if (opteditem.addon.length > 0 || opteditem.variation.length > 0) {
                this.Showfoodcart = true;
            } else {
                this.reduceItemQuantity();
            }
        } else if ((opteditem.addon.length > 0 || opteditem.variation.length > 0) && operation == 'add') {
            this.sameAddon = true;
            this.Showfoodcart = false;
            this.selectedItemWithAddon = JSON.parse(JSON.stringify(opteditem));
        } else {
            this.addItemQuantity('');
        }
        // this.addItemQunatityIndex = this.foodBasket.findIndex((ele: any) => ele.item.id == opteditem.id);
        // this.foodBasket.forEach((ele: any) => {
        //     if (ele.item.id == opteditem.id) {
        //         if (ele.item.addon.length > 0 || ele.item.variation.length > 0) {
        //             operation == 'reduce' ? this.reduceItemQuantity() : this.sameAddon = true;
        //             // this.sameAddon = true;
        //         } else {
        //             operation == 'add' ? this.addItemQuantity() : this.reduceItemQuantity();
        //         }
        //     }
        // });
    }

    addFromCart(opteditem: any, Itemindex: number, operation: string) {
        this.addItemQunatityIndex = Itemindex;
        operation == "add" ? this.addItemQuantity('') : this.reduceItemQuantity();
    }

    addItemwithNewAddon() {
        this.sameAddon = false;
        this.selectItem(this.selectedItemWithAddon);
    }

    /**
     * Method to add qunatity of same item
     */
    addItemQuantity(equivalent: string): void {
        console.log('add qnty method', this.foodBasket[this.addItemQunatityIndex], this.addItemQunatityIndex);
        if (equivalent == "same") {
            this.Showfoodcart = true;
        }
        this.sameAddon = false;
        if (this.foodBasket[this.addItemQunatityIndex]) {
            this.foodBasket[this.addItemQunatityIndex].item.quantity += 1;
        }


        this.storefoodBasketData();
        this.calculateCartPrice();
    }
    /**
     * Method to reduce quantity of the item and remove from array if quantity is 1
     */
    reduceItemQuantity(): void {
        this.sameAddon = false;
        console.log(this.seletedItemId, this.foodBasket[this.addItemQunatityIndex], this.addItemQunatityIndex);
        if (this.foodBasket[this.addItemQunatityIndex].item.quantity == 1) {
            this.seletedItemId = this.seletedItemId.filter((id: string) => id != this.foodBasket[this.addItemQunatityIndex].item.id);
            this.foodBasket.splice(this.addItemQunatityIndex, 1);

        } else {
            this.foodBasket[this.addItemQunatityIndex].item.quantity -= 1;
        }

        this.storefoodBasketData();
        this.calculateCartPrice();
    }
    /**
     * To Check the selected addon on add quantity is same or not
     * @param newItemAddon : Value of the selected addon 
     * @param existingItemAddon : Existing addon value of that item
     * @returns : Boolean value of true / false is added addon Item same or not
     */
    sameAddonItems(newItemAddon: any, existingItemAddon: any): Boolean {
        console.log(newItemAddon, existingItemAddon);

        const sortedArray1 = newItemAddon.slice().sort();
        const sortedArray2 = existingItemAddon.slice().sort();

        let is_equal: Boolean = (newItemAddon.length == existingItemAddon.length) && sortedArray1.every((element: any, index: number) => {
            return element === sortedArray2[index];
        });

        return is_equal;
    }

    /**
     * To store food basket data in local storage so that on going back to menu-page data can be taken from local storage 
     */
    storefoodBasketData(): void {
        localStorage.setItem("foodBasket", JSON.stringify(this.foodBasket));

        this.calculateCartPrice();
    }

    /**
     * To display sub-total price of items added
     */
    calculateCartPrice(): void {

        this.cartItemPrice = 0;
        const tempFoodBasket = JSON.parse(JSON.stringify(this.foodBasket));
        tempFoodBasket.forEach((ele: any) => {

            if (ele.item.quantity == undefined) {
                // Item price will be 0 if there is variation
                if (ele.item.price == 0) {
                    this.cartItemPrice = this.cartItemPrice + parseFloat(ele.addonVariation.varients.price);
                } else {
                    this.cartItemPrice = this.cartItemPrice + parseFloat(ele.item.price);
                }
            } else {
                // Item price will be 0 if there is variation
                if (ele.item.price == 0) {
                    this.cartItemPrice = this.cartItemPrice + (parseFloat(ele.addonVariation.varients.price) * parseInt(ele.item.quantity));
                } else {
                    this.cartItemPrice = this.cartItemPrice + (parseFloat(ele.item.price) * parseInt(ele.item.quantity));
                }

            }
        });
        console.log(this.cartItemPrice);

    }

    showFoodPagecart() {
        this.Showfoodcart = true;
    }

    search(): void {
        if (this.searchTerm.length > 0) {
            this.showResults = true;
            this.fetchPlacePrediction().subscribe(results => {
                this.searchResults = results;
            });
        } else {
            this.showResults = false;
        }
    }

    fetchPlacePrediction(): Observable<{ placeId: string, text: string }[]> {
        return this.apiService.getMethod(`/location/maps/predict?search=${this.searchTerm}`).pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            switchMap(response => {
                const suggestions: any[] = response?.data?.[0]?.suggestions || [];
                const placeIdTextArray = suggestions.map(suggestion => {
                    const placeId = suggestion.placePrediction.placeId;
                    const text = suggestion.placePrediction.text.text;
                    return { placeId, text };
                });
                return of(placeIdTextArray);
            })
        );
    }


    selectResult(result: { placeId: string, text: string }): void {

        this.searchTerm = result.text;
        this.showResults = false;
        this.searchPlaceId = result.placeId;
        this.errorMessage = '';
        this.apiService.getMethod(`/location/maps/place/${this.restaurentId}?placeId=${result.placeId}`).subscribe({
            next: (response: { data: LocationData[] }) => {
                const locationData: any = response.data[0];
                console.log(locationData);
                const tempcustomDetailsformattedAddress = {
                    addressOne: locationData.addressOne,
                    addressTwo: locationData.addressTwo,
                    addressType: locationData.addressType,
                    landmark: locationData.landmark,
                    city: locationData.city,
                    state: locationData.state,
                    country: locationData.country,
                    pincode: locationData.pincode
                }
                let formattedAddress = Object.values(tempcustomDetailsformattedAddress).filter(part => part !== null && part !== undefined).join(', ');
                locationData.formattedAddress = formattedAddress;

                localStorage.setItem('selectedLocation', JSON.stringify(locationData));
                this.loadAddress();
                this.addrSearch = !this.addrSearch;
            },
            error: error => {
                if (error.status === 400) {
                    this.unServiceable()
                    console.error('Bad request error:', error);
                    this.errorMessage = error.error.message
                }
                console.error('Error fetching location data:', error);
            }
        });
    }


    unServiceable() {
        this.unServiceableValue = true;
    }

    closeUnServiceable() {
        this.unServiceableValue = false;
        this.errorMessage = '';
    }
}


