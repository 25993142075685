import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HeaderComponent } from '../../theme/components/header/header.component';
import { FooterComponent } from '../../theme/components/footer/footer.component';
import { FormsModule, } from '@angular/forms';
import { AbstractControl, FormControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApiService } from '../../core/services/api.service';
import { CommonModule } from '@angular/common';
import { SharedService } from '../../core/services/shared.service';
import { Observable, catchError, debounceTime, distinctUntilChanged, of, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { Config } from '../../core/config';
import { FoodMenuComponent } from '../food-menu/food-menu.component';

import { ToastModule } from 'primeng/toast';
import {MessageService, PrimeNGConfig} from 'primeng/api';



interface LocationData {
  id: string;
  formattedAddress: string;
  location: {
    latitude: number;
    longitude: number;
  };
}

@Component({
  selector: 'app-address',
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    ToastModule
   ],
  templateUrl: './address.component.html',
  styleUrl: './address.component.scss',
  providers: [MessageService]
})
export class AddressComponent {


  addressForm!: FormGroup;
  submitted = false;

  currentPage: string = "address";
  addNew: boolean = false;
  addrSearch: boolean = false;
  editAddr: boolean = false;
  customerDetails: any = {};
  selectedLocation: any = {};
  address: any = [];
  pickedAddressindex!: number;

  searchTerm: string = '';
  searchPlaceId: string = '';
  searchResults: { placeId: string, text: string }[] = [];
  showResults: boolean = false;

  unServiceableValue: boolean = false;
  activeTab: number = 1;
  locationEnabled:boolean = true;
  restaurentId: number | undefined;
  // restaurentId: number = Number(Config.rest_id);
  restaurentActive: boolean = true;
  errorMessage!: string;


  @Output() closeDelivery = new EventEmitter<any>();
  @Input() showHeader:boolean = true;
  checkDeleteAddress: boolean = false;
  deleteAddressIndex!: string;

  constructor(
    public apiService: ApiService,
    public sharedDervice: SharedService,
    private router: Router,
    private formBuilder: FormBuilder, 
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private foodMenuComponent: FoodMenuComponent) {

  }


  ngOnInit() {
    let restId:any = localStorage.getItem("selectedRestId")
    this.restaurentId = parseInt(restId);
    
    this.addressForm = this.formBuilder.group({
      id: new FormControl(),
      addressType: new FormControl('Home', [Validators.required]),
      flatNo: new FormControl('', [Validators.required]),
      addressOne: new FormControl('', [Validators.required]),
      addressTwo: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{6}$')]),
      customerId: new FormControl(),
      location: new FormControl(),
    });
    let custDetail: any = localStorage.getItem('customerDetails');

    this.selectedLocation = localStorage.getItem('selectedLocation');
    this.customerDetails = JSON.parse(custDetail);
    console.log(this.customerDetails);

    this.setFormPrefill(JSON.parse(this.selectedLocation));
    // this.address = this.customerDetails.addresses;
    this.getAddresssDetails();

    this.primengConfig.ripple = true;

  
    
       
  }
  setFormPrefill(location: any) {

    if (location != undefined) {
      Object.keys(location)?.forEach((ele: any) => {
        if (location[ele] != null || undefined)
          this.addressForm.controls[ele]?.patchValue(location[ele]);

        if (this.addressForm.controls[ele] == undefined) {
          this.addressForm.addControl(ele, new FormControl(location[ele]));
        }
      });
    }
  }

  openAddress() {
    if (this.selectedLocation && this.selectedLocation !== '') {
      console.log('opening openAddressForm')
      this.openAddressForm();
    } else {
      this.openAddressSearch(); // Otherwise, open address search form
    }
  }

  openAddressForm() {
    console.log(this.selectedLocation)
    this.addNew = true;
  }
  openAddressSearch() {
    this.addrSearch = !this.addrSearch;
  }

  get addressFormError(): { [key: string]: AbstractControl } {
    return this.addressForm.controls;
  }

  getAddresssDetails() {
    this.apiService.getMethod(`/address?customerId_eq=${this.customerDetails.id}`).subscribe({
      next: (reponse) => {
        console.log(reponse);

        if (reponse.data.length > 0) {
          this.address = reponse.data;
        }
      },
      error: (error) => { console.log(error) }
    })
  }

  addAddress() {
    this.submitted = true;
    if (this.addressForm.invalid) return;
    if (this.addressForm.valid) {
      this.addressForm.patchValue({ customerId: this.customerDetails.id });
      this.addressForm.value.addressOne = this.addressForm.value.flatNo + ', ' + this.addressForm.value.addressOne;
      this.addressForm.value.formattedAddress = this.addressForm.value.flatNo + ', ' + this.addressForm.value.formattedAddress;
      this.apiService.postMethod(`/address`, this.addressForm.value).subscribe({
        next: (reponse) => {
          this.address = reponse.data[0].addresses;
          this.closeAddressForm();
          this.messageService.add({severity:'success', detail:'Address Added.'});
          this.getAddresssDetails();
        },
        error: (error) => { console.log(error) }
      });
    }
  }
  updateAddress() {
    console.log(Object(this.addressForm.value));
    this.submitted = true;
    if (this.addressForm.invalid) return;
    if (this.addressForm.valid) {
      this.addressForm.patchValue({ customerId: this.customerDetails.id });
      this.addressForm.value.addressOne = this.addressForm.value.flatNo + ', ' + this.addressForm.value.addressOne;
      this.addressForm.value.formattedAddress = this.addressForm.value.flatNo + ', ' + this.addressForm.value.formattedAddress;
      this.apiService.patchMethod(`/address/${this.addressForm.value.id}`, this.addressForm.value).subscribe({
        next: (reponse) => {
          this.closeAddressForm();
          this.messageService.add({severity:'success', detail:'Address Updated.'});
          this.getAddresssDetails();
        },
        error: (error) => { console.log(error) }
      });
    }
  }

  editAddress(item: any) {
    this.addressForm.patchValue({
      id: item.id,
      addressType: item.addressType,
      flatNo:  item.addressOne.includes(',') ? item.addressOne.split(',')[0] : '',
      addressOne: item.addressOne.includes(',') ? item.addressOne.split(',')[1] : item.addressOne,
      addressTwo: item.addressTwo,
      city: item.city,
      state: item.state,
      country: item.country,
      pincode: item.pincode
    }); this.editAddr = !this.editAddr;
  }
  

  selectedAddress(index: number) {
    this.pickedAddressindex = index;
  }

  proceedOrder() {
    this.sharedDervice.SelecetdAddress(this.address[this.pickedAddressindex]);
    this.router.navigateByUrl('/cart');
  }

  closeAddressForm() {
    if (this.addNew) this.addNew = !this.addNew;
    if (this.editAddr) this.editAddr = !this.editAddr;
  }


  search(): void {
    if (this.searchTerm.length > 0) {
      this.showResults = true;
      this.fetchPlacePrediction().subscribe(results => {
        this.searchResults = results;
      });
    } else {
      this.showResults = false;
    }
  }

  fetchPlacePrediction(): Observable<{ placeId: string, text: string }[]> {
    return this.apiService.getMethod(`/location/maps/predict?search=${this.searchTerm}`).pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(response => {
        const suggestions: any[] = response?.data?.[0]?.suggestions || [];
        const placeIdTextArray = suggestions.map(suggestion => {
          const placeId = suggestion.placePrediction.placeId;
          const text = suggestion.placePrediction.text.text;
          return { placeId, text };
        });
        return of(placeIdTextArray);
      })
    );
  }


  selectResult(result: { placeId: string, text: string }): void {

    this.searchTerm = result.text;
    this.showResults = false;
    this.searchPlaceId = result.placeId;

    this.apiService.getMethod(`/location/maps/place/${this.restaurentId}?placeId=${result.placeId}`).subscribe({
      next: (response: { data: LocationData[] }) => {

        const locationData = response.data[0];
        console.log('fro api ' + JSON.stringify(locationData));
        this.setFormPrefill(locationData);
        this.addrSearch = !this.addrSearch;
        this.openAddressForm();
      },
      error: error => {
        if (error.status === 400) {
          this.unServiceable()
          console.error('Bad request error:', error);
          this.errorMessage = error.error.message
        }
        console.error('Error fetching location data:', error);
      }
    });
  }


  unServiceable() {
    this.unServiceableValue = true;
  }

  closeUnServiceable() {
    this.unServiceableValue = false;
  }

  deleteAddress(address:any){
    this.checkDeleteAddress = true;
    this.deleteAddressIndex = address.id;
    
  }

  confirmDeleteAddress(){
    this.checkDeleteAddress = false;
    this.apiService.deleteMethod(`/address/${this.deleteAddressIndex}`).subscribe({
      next: (reponse) => {
        if(reponse.status == 200){
          this.deleteAddressIndex = '';
          this.messageService.add({severity:'success', detail:'Address Deleted.'});
          this.getAddresssDetails();
        }
        
      },
      error: (error) => { console.log(error) }
    })
  }
}
