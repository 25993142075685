import { Component } from '@angular/core';
import { HeaderComponent } from "../../theme/components/header/header.component";
import { FooterComponent } from "../../theme/components/footer/footer.component";
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-checkout',
    standalone: true,
    templateUrl: './checkout.component.html',
    styleUrl: './checkout.component.scss',
    imports: [
        RouterLink, 
        HeaderComponent, 
        FooterComponent]
})
export class CheckoutComponent {
    currentPage:string = "checkout"
}
