import { ApplicationConfig, importProvidersFrom} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
// import { provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './core/services/auth.service';


export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes),
    // provideHttpClient(),
    importProvidersFrom(HttpClientModule),
    provideAnimations(),
    AuthService
  ],
  
};
