<div>
    <app-header [pageName]="currentPage"></app-header>

    <section class="food__grid__sidebar bg--white" style="padding-bottom:30px;">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="food__sidebar">
                        <div class="food__category__area mt--60">
                            <h4 class="side__title">Order Tracking</h4>
                        </div>
                       
                    </div>
                    <div class="row">
                        <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="food__sidebar">
                                <div class="food__category__area">
                                    <h4 class="side__title">Estimate Time : <span style="color: #d50c0d;">{{orderStatus?.expectedDeliveryTime}}</span></h4>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="food__sidebar">
                                <div class="food__category__area">
                                    <h4 class="side__title">Order Number : <span style="color: #d50c0d;">{{currentOrder.data[0].id}}</span></h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="food__sidebar">
                                <div class="food__category__area">
                                    <h4 class="side__title">Status : <span style="color: #d50c0d;">{{orderStatus?.status}}</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 padd20">
                            <!-- <section class="root">
                                Status : {{orderStatus?.status}}
                                <div class="order-track">
                                    <div class="order-track-step" [ngClass]="{'completed': isOrderStatusReached('CONFIRMED')}">
                                        <div class="order-track-status">
                                            <span class="order-track-status-dot"><i class="fa fa-check" style="font-size: 20px;color:#fff;padding:8px;" *ngIf="isOrderStatusReached('CONFIRMED')"></i></span>
                                            <span class="order-track-status-line"></span>
                                        </div>
                                        <div class="order-track-text">
                                            <p class="order-track-text-stat">Order Confirmed</p>
                                            <span class="order-track-text-sub">Your order has been confirmed.</span>
                                        </div>
                                    </div>
                                    <div class="order-track-step" [ngClass]="{'completed': isOrderStatusReached('PAID')}">
                                        <div class="order-track-status">
                                            <span class="order-track-status-dot"><i class="fa fa-check" style="font-size: 20px;color:#fff;padding:8px;" *ngIf="isOrderStatusReached('PAID')"></i></span>
                                            <span class="order-track-status-line"></span>
                                        </div>
                                        <div class="order-track-text">
                                            <p class="order-track-text-stat">Payment Received</p>
                                            <span class="order-track-text-sub">We have received your payment.</span>
                                        </div>
                                    </div>
                                    <div class="order-track-step" [ngClass]="{'completed': isOrderStatusReached('ACCEPTED')}">
                                        <div class="order-track-status">
                                            <span class="order-track-status-dot"><i class="fa fa-check" style="font-size: 20px;color:#fff;padding:8px;" *ngIf="isOrderStatusReached('ACCEPTED')"></i></span>
                                            <span class="order-track-status-line"></span>
                                        </div>
                                        <div class="order-track-text">
                                            <p class="order-track-text-stat">Order Accepted</p>
                                            <span class="order-track-text-sub">Your order has been accepted.</span>
                                        </div>
                                    </div>
                                    <div class="order-track-step" [ngClass]="{'completed': isOrderStatusReached('READY_FOR_PICKUP')}">
                                        <div class="order-track-status">
                                            <span class="order-track-status-dot"><i class="fa fa-check" style="font-size: 20px;color:#fff;padding:8px;" *ngIf="isOrderStatusReached('READY_FOR_PICKUP')"></i></span>
                                            <span class="order-track-status-line"></span>
                                        </div>
                                        <div class="order-track-text">
                                            <p class="order-track-text-stat">Ready for Pickup</p>
                                            <span class="order-track-text-sub">Your order is ready for pickup.</span>
                                        </div>
                                    </div>
                                    <div class="order-track-step" [ngClass]="{'completed': isOrderStatusReached('OUT_FOR_DELIVERY')}">
                                        <div class="order-track-status">
                                            <span class="order-track-status-dot"><i class="fa fa-check" style="font-size: 20px;color:#fff;padding:8px;" *ngIf="isOrderStatusReached('READY_FOR_PICKUP')"></i></span>
                                            <span class="order-track-status-line"></span>
                                        </div>
                                        <div class="order-track-text">
                                            <p class="order-track-text-stat">Out for Delivery</p>
                                            <span class="order-track-text-sub">Your order is out for delivery.</span>
                                        </div>
                                    </div>
                                    <div class="order-track-step"  [ngClass]="{'completed': isOrderStatusReached('DELIVERED')}">
                                        <div class="order-track-status">
                                            <span class="order-track-status-dot"><i class="fa fa-check" style="font-size: 20px;color:#fff;padding:8px;" *ngIf="isOrderStatusReached('DELIVERED')"></i></span>
                                            <span class="order-track-status-line"></span>
                                        </div>
                                        <div class="order-track-text">
                                            <p class="order-track-text-stat">Order Delivered</p>
                                            <span class="order-track-text-sub">Your order has been delivered.</span>
                                        </div>
                                    </div>
                                </div>
                            </section> -->
                           
                            <div class="row justify-content-between">
                                <div class="order-tracking" [ngClass]="{'completed': isOrderStatusReached('CONFIRMED')}">
                                    <span class="is-complete"></span>
                                    <p>Order Confirmed
                                        <!-- <br> <span> Your order has been confirmed.</span> -->
                                    </p>
                                </div>
                                <div class="order-tracking" [ngClass]="{'completed': isOrderStatusReached('PAID')}">
                                    <span class="is-complete"></span>
                                    <p>Payment Received
                                        <!-- <br> <span> We have received your payment.</span> -->
                                    </p>
                                </div>
                                <div class="order-tracking" [ngClass]="{'completed': isOrderStatusReached('ACCEPTED')}">
                                    <span class="is-complete"></span>
                                    <p>Order Accepted
                                        <!-- <br> <span> Your order has been accepted.</span> -->
                                    </p>
                                </div>
                                <div class="order-tracking" [ngClass]="{'completed': isOrderStatusReached('PICKED_UP')}">
                                    <span class="is-complete"></span>
                                    <p>Order Picked Up
                                        <!-- <br> <span> Your order is ready for pickup.</span> -->
                                    </p>
                                </div>
                                <div class="order-tracking" [ngClass]="{'completed': isOrderStatusReached('OUT_FOR_DELIVERY')}">
                                    <span class="is-complete"></span>
                                    <p>Out for Delivery
                                        <!-- <br> <span> Your order is out for delivery.</span> -->
                                    </p>
                                </div>
                                <div class="order-tracking" [ngClass]="{'completed': isOrderStatusReached('DELIVERED')}">
                                    <span class="is-complete"></span>
                                    <p>Order Delivered
                                        <!-- <br> <span> Your order has been delivered.</span> -->
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        
                       
                        @if (orderStatus?.deliveryTrackingLink != null ) {
                            <a class="food__btn delivery-track-btn" (click)="navigateMap()" >View Delivery Tracking</a>
                        <!-- <iframe style="height: 380px; width: 100%;"  id="myIframe" [src]="orderStatus.deliveryTrackingLink | safe"></iframe> -->
                        }
                       
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</div>