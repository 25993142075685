<div>
    <app-header [pageName]="currentPage"></app-header>



    <section class="food__grid__sidebar  bg--white">
        <div class="container">
            <div class="row">
                <!-- Start Sidebar -->
                <div class="col-lg-3 col-md-12 col-sm-12">
                    <div class="food__sidebar">
                        <div class="food__category__area mt--60">
                            <h4 class="side__title">Profile
                                <div class="grid__show__btn" style="float: right;">
                                    <a class="food__btn" style="cursor: pointer;" (click)="logout()">Logout </a>
                                </div>

                            </h4>
                            <ul class="food__category">
                                <!-- <li style="cursor: pointer;" (click)="activePage=0" [ngClass]="{ 'selected-menu-list': activePage == 0 }"><a>Your Profile </a></li> -->
                                <li style="cursor: pointer;" (click)="activePage=1"
                                    [ngClass]="{ 'selected-menu-list': activePage == 1 }"><a>Your Orders </a></li>
                                <li style="cursor: pointer;" (click)="activePage=2"
                                    [ngClass]="{ 'selected-menu-list': activePage == 2 }"><a>Saved Address </a></li>
                                <!-- <li style="cursor: pointer;" (click)="activePage=3" [ngClass]="{ 'selected-menu-list': activePage == 3 }"><a>Logout </a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                @if(activePage==1){
                <!-- End Sidebar -->
                <div class="col-lg-9 col-md-12 col-sm-12 md--mt--40 sm--mt--40"
                    style="min-height: 520px;border-left:solid 1px #d50c0d;">
                    <div class="food__blog__grid__left__sidebar">
                        <div class="grid__big__product mb--90 foo">
                            <div class="food__category__area mt--60">
                                <h4 class="side__title">Your Orders</h4>
                            </div>
                            @if (!showTable) {
                            <div class="back-btn">
                                <span (click)="showTable = true;"><i class="fa fa-chevron-left" aria-hidden="true"></i>
                                    Back</span>
                            </div>
                            }
                            <div class="cart-main-area bg--white">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 ol-lg-12">
                                            <!-- <form action="#">
                                                <div class="table-content table-responsive">
                                                    <table>
                                                        <thead>
                                                            <tr class="title-top">
                                                                <th class="product-thumbnail">Image</th>
                                                                <th class="product-name">Product</th>
                                                                <th class="product-price">Price</th>
                                                                <th class="product-quantity">Quantity</th>
                                                                <th class="product-subtotal">Total Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td class="product-thumbnail">
                                                                    <a href="#"><img src="../../../assets/images/menu-grid/3.jpg" alt="product img" /></a>
                                                                </td>
                                                                <td class="product-name"><a href="#">Standered post formet</a></td>
                                                                <td class="product-price"><span class="amount">₹165.00</span></td>
                                                                <td class="product-quantity">1</td>
                                                                <td class="product-subtotal">₹165.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="product-thumbnail">
                                                                    <a href="#"><img src="../../../assets/images/menu-grid/3.jpg" alt="product img" /></a>
                                                                </td>
                                                                <td class="product-name"><a href="#">Standered post formet</a></td>
                                                                <td class="product-price"><span class="amount">₹50.00</span></td>
                                                                <td class="product-quantity">1</td>
                                                                <td class="product-subtotal">₹50.00</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </form> -->
                                            <div class="card">
                                                <div class="row">
                                                    <!-- <button (click)="showTable = true;" type="button" class="food__btn btn-back"><span>Back</span></button> -->
                                                    <!-- <div class="col-12">
                                                            <span>Order Time : {{viewItem.orderTime | date: 'dd/MM/yyyy'}}</span>
                                                            @for (item of viewItem.orderItems; track $index) {
                                                                <span>Name    :{{item.name | removeSpecialCharacter}}</span>
                                                                <span>Price   :{{item.price}}</span>
                                                                <span>Quntity :{{item.quantity}}</span>
                                                            }
                                                        </div> -->
                                                    <!-- <div class="row order-head">
                                                        <div class="col-4 order-date">
                                                            Order Date & Time : <span> {{viewItem.orderTime | date:
                                                                'dd/MM/yyyy hh:mm a'}}</span>
                                                        </div>
                                                        <div class="col-4 total-amount">
                                                            Total amount : <span> {{viewItem.totalAmount | number:
                                                                '1.2-2'}} + {{viewItem.deliveryCharge | number:
                                                                '1.2-2'}} </span>
                                                        </div>
                                                        <div class="col-4 status">
                                                            Status : <span> {{viewItem.status}}</span>
                                                        </div>
                                                        <div class="col-12 address">
                                                            Address :
                                                        </div>
                                                    </div> -->


                                                </div>
                                                @if (showTable) {

                                                <!-- ORDER HISTROY TABLE DESIGN    -->
                                                <!-- <p-table [value]="orderHistory" [tableStyle]="{ 'min-width': '50rem' }">
                                                    <ng-template pTemplate="header">
                                                        <tr class="center-text">
                                                            <th>S.No</th>
                                                            <th>Date</th>
                                                            <th>Amount</th>
                                                            <th>Status</th>
                                                            <th>View</th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-orderHistory>
                                                        <tr class="center-text">
                                                            <td class="p-left-3">{{ orderHistory.SNo }}</td>
                                                            <td>{{ orderHistory.orderTime | date: 'dd/MM/yyyy'}}</td>
                                                            <td class="p-left-4">{{ orderHistory.totalAmount }}</td>
                                                            <td class="p-left-1">{{ orderHistory.status }}</td>
                                                            <td class="p-left-3" style="cursor: pointer;" title="View" (click)="expandView(orderHistory)"><i class="fa fa-eye" aria-hidden="true"></i></td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table> -->
                                                <div class="order-card-container">
                                                    @for (item of orderHistory; track $index) {
                                                        @if (item.restaurantId== restaurentId) {
                                                            <div class="order-card">
                                                                <div class="row">
                                                                    <div class="col-md-10 col-sm-10 col-6">
                                                                        <div class="row">
                                                                            <div class="col-md-6">
                                                                                <div><span class="order-ele">Order Id :
                                                                                        &nbsp;</span> <span class="order-id">#
                                                                                        {{item.id}}</span></div>
                                                                                <div><span class="order-ele">Date
                                                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                                                                                        &nbsp;</span> {{ item.orderTime | date:
                                                                                    'dd/MM/yyyy'}}</div>
                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                <div><span class="order-ele">Amount :
                                                                                        &nbsp;</span> {{ item.totalAmount }}
                                                                                </div>
                                                                                <div><span class="order-ele">Status
                                                                                        &nbsp;&nbsp;&nbsp;&nbsp;: &nbsp;
                                                                                    </span>{{ item.status }}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-2 col-sm-2 col-6 view-btn-block">
                                                                        <span class="view-btn" title="View"
                                                                            (click)="expandView(item)">View Details <i
                                                                                class="fa fa-angle-right"
                                                                                aria-hidden="true"></i></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    }
                                                </div>

                                                } @else {
                                                <div class="row view-order-history">
                                                    <!-- <button (click)="showTable = true;" type="button" class="food__btn btn-back"><span>Back</span></button> -->
                                                    <!-- <div class="col-12">
                                                            <span>Order Time : {{viewItem.orderTime | date: 'dd/MM/yyyy'}}</span>
                                                            @for (item of viewItem.orderItems; track $index) {
                                                                <span>Name    :{{item.name | removeSpecialCharacter}}</span>
                                                                <span>Price   :{{item.price}}</span>
                                                                <span>Quntity :{{item.quantity}}</span>
                                                            }
                                                        </div> -->
                                                    <div class="row order-head">
                                                        <div class="col-md-6 col-12 order-date">
                                                            Order Date & Time : <span> {{viewItem.orderTime | date:
                                                                'dd/MM/yyyy hh:mm a'}}</span>
                                                        </div>
                                                        <div class="col-md-6 col-12 total-amount">
                                                            Total amount : <span> {{viewItem.totalAmount | number:
                                                                '1.2-2'}} + {{viewItem.deliveryCharge | number:
                                                                '1.2-2'}} </span>
                                                        </div>
                                                        <div class="col-md-6 col-12 status">
                                                            Status : <span> {{viewItem.status}}</span>
                                                        </div>
                                                        <div class="col-md-12 col-12 address">
                                                            Address : {{this.addressDetails}}
                                                        </div>
                                                    </div>

                                                    <div class="main-item-container">

                                                        @for (item of viewItem.orderItems; track $index) {
                                                        <div class="item-container">

                                                            <h2 style="font-size:14px;">{{item.name |
                                                                removeSpecialCharacter}}</h2>
                                                            <div class="row ">
                                                                <div class="col-6 price">
                                                                    Price: ₹ <span>{{item.price}}</span></div>
                                                                <div class="col-6 quantity">Qunatity:
                                                                    <span>{{item.quantity}}</span>
                                                                </div>
                                                            </div>
                                                            <div class="addon-list">
                                                                @if (item.orderAddonItems.length >0 ) {
                                                                <span class="adon-heading">AddOns: </span> @for (aditem
                                                                of item?.orderAddonItems; track $index; let last =
                                                                $last) {
                                                                <span>{{aditem.addonItemName}}</span> @if (!last)
                                                                {<span>, </span>} } }
                                                            </div>
                                                            <!-- <div class="food__menu__inner d-flex flex-wrap flex-md-nowrap flex-lg-nowrap">
                                                                <div class="food__menu__details">
                                                                    <div class="food__menu__content">
                                                                        <h2 style="font-size:14px;">{{item.name | removeSpecialCharacter}}</h2>
                                                                        <ul class="food__dtl__prize rating"style="    padding-bottom: 8px;margin-bottom: 8px;">
                                                                            <li style="font-size: 16px;">₹ {{item.price}}</li>
                                                                            <li style="font-size: 16px;">
                                                                                <span class="product-quantity">
                                                                                    {{item.quantity}}</span>
                                                                            </li>
                                                                        </ul>
                                                                        @if (basketitem.item.addon.length >0 || basketitem.item.variation.length >0) {
                                                                                    <li class="addon-list">
                                                                                        AddOns: @for (item of basketitem?.addonVariation?.addOnNames; track $index; let last = $last) {
                                                                                        <span>{{item}}</span> @if (!last) {<span>, </span>} } @for (item of basketitem?.addonVariation?.VatiationAddOnName; track $index; let last = $last) {
                                                                                        <span>{{item}}</span> @if (!last) {<span>, </span>} }
                                                                                    </li>
                                                                                    }
                                                                        @if(item.description){
                                                                                <div class="description-text-wrapper">
                                                                                    <p class="description-text" style="margin: 0px;font-size: 13px;line-height: 18px;     overflow-y: scroll !important;
                                                                                    height: 50px !important;">
                                                                                        {{item.description}}
                                                                                    </p>
                                                                                </div>
                                                                                }
                                                                    </div>
                                                                </div>
                                                            </div> -->
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="product-wrapper" style="background-color: #e9ecef;border-radius: 12px;">

                        <div class="food__search mt--30" style="padding: 30px 30px;">

                            <h4 class="side__title">Ankapur</h4>
                            <div class="deliveryMode">
                                <div class="service-category" style="color: #4d4d4d;">
                                    <i _ngcontent-ng-c3623454867="" aria-hidden="true" class="fa fa-map-marker"
                                        style="color: #d50c0d;padding-right: 10px;"></i>
                                    <span class="address">H.No. 1-98/6A/14, Plot no 42 Road no.3, Patrika nagar Hitech City
                                        Nagar,<br> Madhapur, Hyderabad, Telangana 500049</span><br>
                                    <span class="address">7995854986</span><br>
                                    <!-- <span class="address">Fssai- 13623013000801</span><br><br> -->

                                    <div class="address">
                                        <img src="../../../assets/images/logo/hyperapps-horizontal.png"
                                            style="max-width: 250px;">
                                    </div>
                                </div>

                            </div>


                        </div>








                    </div>
                </div>
                } @if(activePage==2){
                <div class="col-lg-9 col-md-12 col-sm-12 md--mt--40 sm--mt--40"
                    style="min-height: 520px;border-left:solid 1px #d50c0d">
                    <app-address [showHeader]="showAddressHeader"></app-address>
                    <!-- <div class="food__blog__grid__left__sidebar">
                        <div class="grid__big__product mb--90 foo">
                            <div class="food__category__area mt--60">
                                <h4 class="side__title">Saved Address

                                    <div class="grid__show__btn grid__show__btn11" style="float: right;">
                                        <a class="food__btn" (click)="addNewAddress()" style="cursor: pointer;">Add</a>
                                    </div>
                                </h4>
                            </div>
                            <div class="row">

                                @for (item of address; track $index) {
                                <div class="col-md-6">
                                    <div class="food__menu__container"
                                        style="border: solid 1px #d6d6d6;padding: 8px;border-radius: 10px;margin-bottom: 10px;">
                                        <div class="food__menu__inner d-flex flex-wrap flex-md-nowrap flex-lg-nowrap">
                                            <div class="food__menu__details"
                                                style="padding-left: 10px;width: 100%;    height: 100px;">
                                                <div class="food__menu__content">
                                                    <h2 style="font-size:14px;">
                                                        <section class="fd__service__area">
                                                            <div class="container-fluid">
                                                                <div class="service">
                                                                    <div class="service__title">
                                                                        <div class="ser__icon">
                                                                            <i class="fa fa-home"
                                                                                style="font-size: 20px;"></i>
                                                                            <h2 style="width: 80%;display: inline-block;    font-size: 18px;
                                                                        padding-left: 10px;
                                                                        color: #d50c0d;">{{item.addressType}}</h2>
                                                                        </div>
                                                                    </div>
                                                                    <div class="service__details">
                                                                        <p
                                                                            style="text-transform: initial; margin: 0px;;">
                                                                            {{item.addressOne}}, {{item.addressTwo}},
                                                                            {{item.landmark}},<br> {{item.city}},
                                                                            {{item.state}}, {{item.country}} -
                                                                            {{item.pincode}}.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }

                            </div>
                        </div>
                    </div> -->
                    <div class="product-wrapper" style="background-color: #e9ecef;border-radius: 12px;">

                        <div class="food__search mt--30" style="padding: 30px 30px;">

                            <h4 class="side__title">Ankapur</h4>
                            <div class="deliveryMode">
                                <div class="service-category" style="color: #4d4d4d;">
                                    <i _ngcontent-ng-c3623454867="" aria-hidden="true" class="fa fa-map-marker"
                                        style="color: #d50c0d;padding-right: 10px;"></i>
                                    <span class="address">H.No. 1-98/6A/14, Plot no 42 Road no.3, Patrika nagar Hitech City
                                        Nagar,<br> Madhapur, Hyderabad, Telangana 500049</span><br>
                                    <span class="address">7995854986</span><br>
                                    <!-- <span class="address">Fssai- 13623013000801</span><br><br> -->

                                    <div class="address">
                                        <a href="https://hyperapps.in/"> <img src="../../../assets/images/logo/HyperApps- Horizontal.png"style="max-width: 250px;"></a>
                                    </div>
                                </div>

                            </div>


                        </div>








                    </div>
                </div>
                }
            </div>
        </div>
    </section>







    <app-footer></app-footer>
</div>

@if (addNew) {
<div class="accountbox-wrapper is-visible">
    <div class="body-overlay"></div>
    <div class="accountbox text-left" style="padding: 30px;">
        <ul class="nav accountbox__filters" style="border-bottom: solid 1px #c2c2c2;">
            <li style="text-align: center;width: 100%;padding: 0px;" class="section__title title__style--2">
                <div class="food__menu__content">
                    <h2 style="font-size:20px;color: #d50c0d;padding-bottom: 10px;">Add New Address </h2>
                </div>
            </li>
        </ul>
        <hr>
        <div class="accountbox__inner tab-content">
            <div class="accountbox__login tab-pane fade show active" style="width: 100%;">
                <div class="single-input">

                    <form class="billing-form checkout-form" [formGroup]="addressForm">
                        <div class="row">
                            <div class="col-md-6 col-12 mb--20">
                                <select id="addressType" formControlName="addressType"
                                    style="background-color: transparent;border: 1px solid #aaaaaa;    color: #333;padding:0 30px;"
                                    [ngClass]="{ 'is-invalid': submitted && addressFormError['addressType'].errors }">
                                    <option value="">Address Type</option>
                                    <option value="Home">Home</option>
                                    <option value="Address">Office</option>
                                </select> @if(submitted && addressFormError['addressType'].errors){ @if(submitted &&
                                addressFormError['addressType'].errors['required']){
                                <div style="padding-left: 30px;color: #d50c0d;">Address Type is required</div>
                                } }
                            </div>
                            <div class="col-md-6 col-12 mb--20">
                                <input type="text" placeholder="Address Line 1" id="addressOne"
                                    formControlName="addressOne"
                                    [ngClass]="{ 'is-invalid': submitted && addressFormError['addressOne'].errors }">
                                @if(submitted && addressFormError['addressOne'].errors){
                                @if(submitted && addressFormError['addressOne'].errors['required']){
                                <div style="padding-left: 30px;color: #d50c0d;">Address Line 1 is required</div>
                                } }
                            </div>
                            <div class="col-md-6 col-12 mb--20">
                                <input type="text" placeholder="Address Line 2" id="addressTwo"
                                    formControlName="addressTwo"
                                    [ngClass]="{ 'is-invalid': submitted && addressFormError['addressTwo'].errors }">
                                @if(submitted && addressFormError['addressTwo'].errors){
                                @if(submitted && addressFormError['addressTwo'].errors['required']){
                                <div style="padding-left: 30px;color: #d50c0d;">Address Line 2 is required</div>
                                } }
                            </div>
                            <div class="col-md-6 col-12 mb--20">
                                <input type="text" placeholder="Land Mark" id="landmark" formControlName="landmark"
                                    [ngClass]="{ 'is-invalid': submitted && addressFormError['landmark'].errors }">
                                @if(submitted && addressFormError['landmark'].errors){
                                @if(submitted && addressFormError['landmark'].errors['required']){
                                <div style="padding-left: 30px;color: #d50c0d;">Land Mark is required</div>
                                } }
                            </div>
                            <div class="col-md-6 col-12 mb--20">
                                <input placeholder="City" type="text" id="city" formControlName="city"
                                    [ngClass]="{ 'is-invalid': submitted && addressFormError['city'].errors }">
                                @if(submitted && addressFormError['city'].errors){ @if(submitted &&
                                addressFormError['city'].errors['required']){
                                <div style="padding-left: 30px;color: #d50c0d;">City is required</div>
                                } }
                            </div>
                            <div class="col-md-6 col-12 mb--20">
                                <input type="text" placeholder="State" id="state" formControlName="state"
                                    [ngClass]="{ 'is-invalid': submitted && addressFormError['state'].errors }">
                                @if(submitted && addressFormError['state'].errors){ @if(submitted &&
                                addressFormError['state'].errors['required']){
                                <div style="padding-left: 30px;color: #d50c0d;">State is required</div>
                                } }
                            </div>
                            <div class="col-md-6 col-12 mb--20">
                                <input type="text" placeholder="Country" id="country" formControlName="country"
                                    [ngClass]="{ 'is-invalid': submitted && addressFormError['country'].errors }"
                                    [disabled]="true"> @if(submitted && addressFormError['country'].errors){
                                @if(submitted && addressFormError['country'].errors['required']){
                                <div style="padding-left: 30px;color: #d50c0d;">Country is required</div>
                                } }
                            </div>
                            <div class="col-md-6 col-12 mb--20">
                                <input placeholder="PIN Code" type="text" id="pincode" formControlName="pincode"
                                    [ngClass]="{ 'is-invalid': submitted && addressFormError['pincode'].errors }">
                                @if(submitted && addressFormError['pincode'].errors){ @if(submitted
                                && addressFormError['pincode'].errors['required']){
                                <div style="padding-left: 30px;color: #d50c0d;">PIN Code is required</div>
                                }@if(submitted && addressFormError['pincode'].errors['pattern']){
                                <div style="padding-left: 30px;color: #d50c0d;">Invalid PIN Code</div>
                                } }
                            </div>
                            <div class="col-md-6 col-12 mb--20">
                            </div>

                            <div class="col-md-3 col-12 mb--20">
                                <div class="grid__show__btn">
                                    <a class="food__btn" style="cursor: pointer;width:100%;text-align: center;"
                                        (click)="submitAddressForm()">Save</a>
                                </div>
                            </div>
                            <div class="col-md-3 col-12 mb--20">
                                <div class="grid__show__btn">
                                    <a class="food__btn" style="cursor: pointer;width:100%;text-align: center;    background-color: transparent;
                                    color: #d50c0d;
                                    border:solid 1px #d50c0d;" (click)="closeAddressForm()">Close</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
}