@if(!workingHours){
    <app-delivery-not-available></app-delivery-not-available>
 }
<div>
    <app-header [pageName]="currentPage"></app-header>
    <!-- Start Bradcaump area -->

    <!-- End Bradcaump area -->
    <!-- cart-main-area start -->
    <div class="mt-8 mb-lg-14 mb-8">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="product-wrapper">

                        <div class="food__search mt--30">
                            <h4 class="side__title"> My Order</h4>
                        </div>


                        <div class="row" style="margin-bottom: 60px;">
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="food__search ">
                                            <h4 class="side__title">
                                                <div class="location-wrapper" style="font-size: 15px;">
                                                    <span class="service-category"> <i class="fa fa-map-marker" aria-hidden="true"></i> Address : {{address}}</span>
                                                    <div class="grid__show__btn">
                                                        <a class="food__btn" style="cursor: pointer;text-align: center;" routerLink="/addAddress">Change</a>
                                                    </div>
                                                </div>
                                            </h4>
                                        </div>
                                    </div>

                                    <div class="col-md-4">

                                        <h4 class="side__title">
                                            <div class="location-wrapper" style="font-size: 15px;">
                                                <span class="service-category">
                                            <i class="fa fa-phone" aria-hidden="true"></i>
                                            Mobile : {{mobile}}
                                        </span>
                                                <span class="address">
                                        </span>
                                            </div>

                                        </h4>
                                    </div>
                                </div>
                                <div class="foodMenuHeight">
                                    @for (basketitem of foodBasket; track $index) {
                                    <div class="food__menu__container">
                                        <div class="food__menu__inner d-flex flex-wrap flex-md-nowrap flex-lg-nowrap">
                                            @if(basketitem.item.itemImageUrl != ""){
                                            <div class="food__menu__thumb">
                                                <img [src]="basketitem.item.itemImageUrl" alt="">
                                                <!-- @if (item.addon.length >0 || item.variation.length >0) {
                                                        <div class="grid__show__btn"><a class="food__btn" style="font-size: 10px;height: 20px;
                                                                line-height: 20px;">Customizable</a></div>
                                                        } -->

                                            </div>
                                            }

                                            <div class="food__menu__details">
                                                <div class="food__menu__content">
                                                    <h2 style="font-size:14px;">{{basketitem.item.itemName | removeSpecialCharacter}}</h2>
                                                    <ul class="food__dtl__prize rating" style="    padding-bottom: 8px;margin-bottom: 8px;">
                                                        <li style="font-size: 16px;">₹ {{basketitem | showPriceVariation:''}}</li>
                                                        <!-- <li style="font-size: 16px;">₹ {{basketitem.item.price | showPriceVariation}}</li> -->
                                                        <li style="font-size: 16px;">
                                                            <div class="product-quantity">
                                                                <div class="cart-plus-minus">
                                                                    <div class="dec qtybutton" (click)="sameAddonConfirmation(basketitem.item, $index,'reduce')">-</div>
                                                                    <input class="cart-plus-minus-box" type="text" name="qtybutton" value="{{basketitem.item.quantity}}">
                                                                    <div class="inc qtybutton" (click)="sameAddonConfirmation(basketitem.item, $index,'add')">+</div>
                                                                </div>
                                                                @if(basketitem.item.addon.length >0 || basketitem.item.variation.length >0) {
                                                                    <span class="cust-span" >Customisable</span>
                                                                    <!-- <div class="grid__show__btn"><a class="food__btn"></a></div> -->
                                                                    }
                                                            </div>
                                                            
                                                        </li>


                                                    </ul>
                                                    @if (basketitem.item.addon.length >0 || basketitem.item.variation.length >0) { @if(basketitem?.addonVariation?.varients != undefined){
                                                    <li class="addon-list">Varient: {{basketitem.addonVariation.varients.name}} </li>
                                                    }
                                                    <li class="addon-list">
                                                        AddOns: @for (item of basketitem?.addonVariation?.addOnNames; track $index; let last = $last) {
                                                        <span>{{item}}</span> @if (!last) {<span>, </span>} } @for (item of basketitem?.addonVariation?.VatiationAddOnName; track $index; let last = $last) {
                                                        <span>{{item}}</span> @if (!last) {<span>, </span>} }
                                                    </li>
                                                    }
                                                    <!-- @if(item.description){
                                                        <div class="description-text-wrapper">
                                                            <p class="description-text" style="margin: 0px;font-size: 13px;line-height: 18px;     overflow-y: scroll !important;
                                                            height: 50px !important;">
                                                                {{item.description}}
                                                            </p>
                                                        </div>
                                                        } -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>

                            </div>
                            <div class="col-md-4">
                                <!-- sidebar -->
                                <!-- card -->
                                <div class="mb-5 card mt-6">
                                    <div class="card-body p-6">
                                        <!-- heading -->
                                        <h2 class="h5 mb-4" style="border-bottom:solid 1px #d50c0d;padding-bottom:10px;">Bill Details</h2>
                                        <div class="card mb-2">
                                            <!-- list group -->
                                            <ul class="list-group list-group-flush">
                                                <!-- list group item -->
                                                <!-- addOnPriceSum : 0 itemSubtotal : "638.00" tax : {CGST: 15.96, SGST: 15.96} -->
                                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                                    <div class="me-auto">
                                                        <div>Item Total</div>
                                                    </div>
                                                    <span>₹ {{orderPriceDetails.itemSubtotal + orderPriceDetails.addOnPriceSum | number:'1.2-2'}}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                                    <div class="me-auto">
                                                        <div>Add-On Price</div>
                                                    </div>
                                                    <span>₹ {{orderPriceDetails.addOnPriceSum | number:'1.2-2'}}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                                    <div class="me-auto">
                                                        <div>CGST</div>
                                                    </div>
                                                    <span>₹ {{orderPriceDetails.tax.CGST | number:'1.2-2'}}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                                    <div class="me-auto">
                                                        <div>SGST</div>
                                                    </div>
                                                    <span>₹ {{orderPriceDetails.tax.SGST | number:'1.2-2'}}</span>
                                                </li>
                                                @if (!showAddAddressButton) {
                                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                                    <div class="me-auto">
                                                        <div>Delivery Charge</div>
                                                    </div>
                                                    <span>₹ {{orderPriceDetails.deliveryCharge   | number:'1.2-2'}}</span>
                                                </li>
                                                <!-- <li class="list-group-item d-flex justify-content-between align-items-start">
                                                    <div class="me-auto">
                                                        <div>Tax (Delivery)</div>
                                                    </div>
                                                    <span>₹ {{orderPriceDetails.dcTaxAmount | number:'1.2-2'}}</span>
                                                </li> -->
                                                <!-- list group item -->
                                                <!-- <li class="list-group-item d-flex justify-content-between align-items-start">
                                                    <div class="me-auto">
                                                        <div>Packaging Charge</div>
                                                    </div>
                                                    <span>₹ 0</span>
                                                </li> -->
                                                }

                                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                                    <div class="me-auto">
                                                        <div class="me-auto">Discount ({{flatDiscountpercentage}}% off)</div>
                                                    </div>
                                                    <span> - ₹ {{orderPriceDetails.discount | number:'1.2-2'}}</span>
                                                </li>
                                                <!-- list group item -->
                                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                                    <div class="me-auto">
                                                        <div class="fw-bold">To Pay</div>
                                                    </div>
                                                    <span class="fw-bold">₹ {{orderPriceDetails.toPay | number:'1.2-2'}}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <span class="discount"> <i class="fa fa-tag mr-5" aria-hidden="true"></i>Get Flat {{flatDiscountpercentage}}% off in every order.</span>
                                        <div class="d-grid mb-1 mt-4">
                                            <!-- btn -->
                                            
                                            <div class="grid__show__btn">
                                                @if (showAddAddressButton) {
                                                <a class="food__btn" style="cursor: pointer;width:100%;text-align: center;" routerLink="/addAddress">Select Address</a> } @else if (quoteLoading) {
                                                <a class="food__btn" style="cursor: pointer;width:100%;text-align: center;"><i class="fa fa-circle-o-notch fa-spin"></i> Loading...</a> } @else {
                                                <a class="food__btn" style="cursor: pointer;width:100%;text-align: center;" [class.disabled]="isMakePaymentEnabled" (click)="onPrepareOrderClick()">Make Payment</a> }


                                            </div>

                                        </div>
                                       
                                        <!-- text -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    @if (showPayment) {
    <app-payment [oderData]="orderSaveResponse"></app-payment>
    } @if (sameAddon) {
    <div class="accountbox-wrapper is-visible">
        <div class="body-overlay"></div>
        <div class="accountbox text-left" style="padding: 30px;">
            <span class="close-icon" title="Close" (click)="sameAddon = !sameAddon">
                <i class="zmdi zmdi-close"></i>
            </span>
            <ul class="nav accountbox__filters" style="border-bottom: solid 1px #c2c2c2;">
                <li style="text-align: center;width: 100%;padding: 0px;" class="section__title title__style--2">
                    <div class="food__menu__content">
                        <h2 style="font-size:20px;color: #d50c0d;padding-bottom: 10px;">The selected item has Variation / Addon. Do you want to select the same or New </h2>
                    </div>
                </li>
            </ul>
            <hr>
            <div class="accountbox__inner tab-content">
                <div class="accountbox__login tab-pane fade show active" style="width: 100%;">
                    <div class="single-input" style="text-align: center;">
                        <button (click)="addItemQuantity()" type="button" class="food__btn" style="width: 30%;margin-right: 10px;font-size: 15px;padding: 0px;"><span>Use Same</span></button>
                        <button (click)="addItemwithNewAddon()" type="button" class="food__btn" style="width: 30%;font-size: 15px;padding: 0px;"><span>Select New</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    } @if (showAddonVariationDialig) {
    <app-addonpopup [variations]="variationResponse" [addOnChoice]="addonResponse" [menuItem]="selectedItemWithAddon.itemName" (addedItem)="getAddedItem($event)"></app-addonpopup>
    }
    @if (quoteLoading) { 
        <div class="accountbox-wrapper is-visible">
            <div class="body-overlay"></div>
            <div class="accountbox text-left">
                <ul class="nav accountbox__filters">
                    <li class="section__title title__style--2">
                        <img src="../../../assets/images/getQuoteLoader.gif" alt="">
                        <br><span class="quote-text">Getting best quote.</span>
                    </li>
                </ul>
                <div class="accountbox__inner tab-content">
                    <div class="accountbox__login tab-pane fade show active">
                    </div>
                </div>
            </div>
        </div>
        }
    
    <app-store-closed *ngIf="!restaurentActive"></app-store-closed>
    <app-footer></app-footer>
    <p-toast></p-toast>
</div>