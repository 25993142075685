import { Component, Input, OnInit , EventEmitter, Output} from '@angular/core';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { SharedService } from '../../../core/services/shared.service';
import { ApiService } from '../../../core/services/api.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterLink,FormsModule, CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {

  @Input () pageName:string = '';
  showSearch:boolean = false;
  orderQuantity:number = 0;
  veg:boolean=false;
  headerSearchKeyword:any = '';
  @Output() filterItem= new EventEmitter<any>();
  @Output() filterVeg= new EventEmitter<any>();
  @Output() branchId= new EventEmitter<any>();
  showclearIcon: boolean = false;
  orderNowText:string = "Order Now";
  customerDetails: any = [];
  orderHistory:any = [];
  showTracking: boolean = false;
  support:boolean = false;
  storeOpt:boolean = false;
  displayStoreOption:boolean = false;
  
  vendorData:any = [];
  branchName: any;
  branchContact:any;
  conformChangeBranch:boolean = false;

  constructor(public sharedData: SharedService, public apiService: ApiService) { }

  ngOnInit(): void {
   this.pageName == "food-menu" ? this.showSearch = true : this.showSearch = false;
   this.pageName == "cart" ? this.orderNowText = "Add more" : this.orderNowText = "Order Now";
  
   console.log(this.pageName);
   

   let custDetail: any = localStorage.getItem('customerDetails');
   let vendorDetail: any = localStorage.getItem('vendorData');
  this.sharedData.getbranchData().subscribe((data:any)=>{
      // console.log('brancgdata',data);
      this.vendorData = data;
      if(this.vendorData.restaurantDetails != undefined){
        let restId:any = localStorage.getItem("selectedRestId")
         if(restId == undefined){
          this.ShowBranch(); 
        }else {
          this.vendorData.restaurantDetails.forEach((data:any) =>{
            if(data.id == restId.toString()){
              this.branchName = data.restaurantName;
              this.branchContact = data.contact;
            }
          })
          // this.branchName = this.vendorData.restaurantDetails[this.vendorData.restaurants.indexOf(restId.toString())].restaurantName;
          
        }
      }
      
      });
   this.customerDetails = JSON.parse(custDetail);
  //  this.vendorData = JSON.parse(vendorDetail);
   this.getOrderHistory();

   
  
  }

 

  ShowBranch():void{
    // console.log('this.vendorData ',this.vendorData );
    if(this.vendorData.restaurantDetails.length > 1 && (this.pageName == "food-menu" || this.pageName == "deliveryMode")){
      this.storeOpt = true;
    } 
    // else if(this.vendorData.restaurantDetails.length == 1 ){
    //   localStorage.setItem('selectedRestId',this.vendorData.restaurantDetails[0].id);
    // }
  }
  confirmChangeBranch(){
    this.ShowBranch();
    // localStorage.removeItem('foodBasket');
    this.conformChangeBranch = false;
  }
  changeBranch():void{
    if(this.pageName == "food-menu" || this.pageName == "deliveryMode")
      this.conformChangeBranch=true;
   
  }
  vegMenuFilter(): void{
    this.filterVeg.emit(this.veg);
  }

  categoryFilter():void{
    this.headerSearchKeyword != "" ? this.showclearIcon = true : this.showclearIcon = false;
    this.filterItem.emit(this.headerSearchKeyword);
     
  }

  clearFiler():void{
    this.headerSearchKeyword = "";
    this.categoryFilter();
  }

  toggleSupport():void{
    this.support = !this.support;
  }

  selectBranch(index:number):void{
    console.log(index);
    if(index == 99){
      this. ShowBranch();
    } else {
      localStorage.setItem('selectedRestId',this.vendorData.restaurantDetails[index].id);
      this.storeOpt = false;
      this.branchId.emit(this.vendorData.restaurantDetails[index].id);
      this.branchName = this.vendorData.restaurantDetails[index].restaurantName;
      this.branchContact = this.vendorData.restaurantDetails[index].contact;
    }
    
  }


   /**
   * To fetch order history
   */
   getOrderHistory():void {
    if(this.customerDetails){
      const orderStaus = ['PAID','ACCEPTED','MARK_FOOD_READY','OUT_FOR_PICKUP','REACHED_PICKUP','PICKED_UP','OUT_FOR_DELIVERY','REACHED_DELIVERY']
      this.apiService.getMethod(`/order?customerId_eq=${this.customerDetails.id}`).subscribe({
        next: (reponse) => {
          this.orderHistory = reponse.data;
          if(orderStaus.includes(this.orderHistory[0]?.status)){
            this.showTracking = true;
          }
        },
        error: (error) => { console.log(error) }
      })
    }
  }

 

}
