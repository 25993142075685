<header class="htc__header bg--white">
    <div id="sticky-header-with-topbar" class="mainmenu__wrap ">
        <div class="marquee">
            <div class="track">
                <div class="content">&nbsp; 🚨 Flat 25% Off on all orders! Limited Time Offer, Dont Miss out!</div>
            </div>
        </div>
        <div class="container">
            <div class="row" style="padding: 0px;">
                <div class="col-lg-2 col-md-6 col-sm-2 col-2 logo-container" style="padding: 0px;flex: 1;">
                    <div class="logo">
                        <a href="" onclick="event.preventDefault();" style="display: block;text-align: left;" class="logoStyle">
                            <img class="logo" src="../../../../assets/images/logo/ankapur-logo.png"  routerLink="/order" alt="logo images" style="margin:auto 0;">
                            <span class="storeOption" [ngClass]="{'cursor-default': pageName != 'food-menu' && pageName != 'deliveryMode'}" (click)="changeBranch()">{{branchName}} <i class="fa fa-angle-down"></i></span>
                        </a>

                        <a (click)="toggleSupport()" class="accountbox-trigger">
                            <i class="zmdi zmdi-headset-mic" style="font-size: 30px;color:#d50c0d;padding:0 45px"></i>
                        </a>
                        <a class="accountbox-trigger" routerLink="/user" href="">
                            <i class="zmdi zmdi-account" style="font-size: 30px;color:#d50c0d;"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-10 col-md-2 col-sm-2 col-2 flex1 search-container" style="padding: 0px;   ">
                    <div class="main__menu__wrap">
                        <nav class="main__menu__nav d-lg-block">
                            <ul class="mainmenu">
                                @if (showSearch) {
                                <li>
                                    @if (showTracking) {
                                    <a class="orderNow" routerLink="/order-tracking" href="" style="color: #d50c0d;"> Track Order </a> }
                                </li>
                                <li style="padding: 0px 3px;">
                                    <button class="help"><a (click)="toggleSupport()"><i class="fa fa-headset-mic" aria-hidden="true"></i> Support</a></button>
                                </li>
                                <li style="padding: 0px 3px;">
                                    <div class="input-group mb-3 py-4">
                                        <div class="serch__box">
                                            <input type="text" placeholder="Menu Search" [(ngModel)]="headerSearchKeyword" (input)="categoryFilter();" style="height: 40px;line-height: 40px;border-radius: 8px;"> @if (showclearIcon) {
                                            <a (click)="clearFiler()"> <i style="color:#d50c0d;" class="fa fa-times" title="Clear" aria-hidden="true"></i></a>

                                            } @else {
                                            <a (click)="categoryFilter()"><i class="fa fa-search"></i></a> }

                                        </div>
                                        <!-- <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch"
                                                [(ngModel)]="veg" (change)="vegMenuFilter();">
                                            <label class="form-check-label" for="veg"
                                                style="margin-top: -3px;">Veg</label>
                                        </div> -->
                                    </div>
                                </li>
                                } @else { @if (showTracking) {
                                <a class="orderNow" routerLink="/order-tracking" style="background-color: #d50c0d;font-weight:500;text-align: center;color:#fff;width: 15%;padding: 5px;border-radius: 8px;" href=""> Track Order</a> }
                                <!-- @if(pageName != "deliveryMode"){
                                <li style="padding: 0px 3px;">
                                    <a routerLink="/order" href=""> {{orderNowText}} </a>
                                </li>
                                <span style="border-left: 2px solid #d50c0d; height: 15px; margin: 0 5px;"></span>
                                } -->

                                }
                                <!-- @if (showTracking) {
                                <li style="padding: 0px 3px;">
                                    <a routerLink="/order-tracking" href=""> Track Order </a>
                                </li>
                                } -->

                                @if(pageName != "deliveryMode"){
                                <li>
                                    <div class="log__in">
                                        <a routerLink="/user" class="accountbox-trigger" href="">
                                            <i class="zmdi zmdi-account" style="font-size: 30px;color:#d50c0d;"></i></a>
                                    </div>
                                </li>
                                }

                            </ul>
                        </nav>
                    </div>
                </div>

                <!-- <div class="col-lg-1 col-sm-4 col-md-4">
                    <div class="header__right d-flex justify-content-end"> -->
                <!-- <div class="log__in">
                            <a routerLink="/user" class="accountbox-trigger" href=""><i class="zmdi zmdi-account"></i></a>
                        </div> -->
                <!-- <div class="shopping__cart">
                            <a routerLink="/cart" href=""><i class="zmdi zmdi-shopping-basket"></i></a>
                            <div class="shop__qun">
                                <span>
                                    {{foodBasket.length}}
                                </span>
                            </div>
                        </div> -->
                <!-- </div>
                </div> -->
            </div>
            <div class="row mob-view">
                @if (showSearch) {
                <div class="row">
                    <div class="col-12 input-group mb-3 py-2">
                        <div class="serch__box">
                            <input type="text" placeholder="Menu Search" [(ngModel)]="headerSearchKeyword" (input)="categoryFilter();" style="height: 40px;line-height: 40px;border-radius: 8px;"> @if (showclearIcon) {
                            <a (click)="clearFiler()"> <i class="fa fa-times" title="Clear" aria-hidden="true"></i></a>

                            } @else {
                            <a (click)="categoryFilter()"><i class="fa fa-search"></i></a> }

                        </div>
                        <!-- <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" [(ngModel)]="veg" (change)="vegMenuFilter();">
                            <label class="form-check-label" for="veg" style="margin-top: -3px;">Veg</label>
                        </div> -->
                    </div>
                </div>
                } @else { @if(pageName != "deliveryMode"){

                <a class="orderNow" routerLink="/order" href=""> </a> } } @if (showTracking) {
                <a class="orderNow" routerLink="/order-tracking" style="background-color: #d50c0d;font-weight:500;text-align: center;color:#fff;width: 30%;padding: 5px;border-radius: 8px;" href=""> Track Order</a> }
                <!-- <button class="help"><a (click)="toggleSupport()"><i class="fa fa-phone" aria-hidden="true"></i> Support</a></button>  -->

            </div>
            <!-- Mobile Menu -->
            <div class="mobile-menu d-block d-lg-none"></div>
            <!-- Mobile Menu -->
        </div>
    </div>

    <!-- support popup -->
    @if(support){
    <div class="accountbox-wrapper is-visible">
        <div class="body-overlay"></div>
        <div class="accountbox text-left support-popup">
            <span class="close-icon" title="Close" (click)="toggleSupport()">
                    <a><i class="zmdi zmdi-close" ></i></a>
                </span>
            <ul class="nav accountbox__filters" style="border-bottom: solid 1px #c2c2c2;">
                <li style="text-align: center;width: 100%;padding: 0px;" class="section__title title__style--2">
                    <div class="food__menu__content">
                        <h2 style="font-size:16px;color: #d50c0d;">Support</h2>
                    </div>
                </li>
            </ul>
            <hr>
            <div class="dropdown">
                <div id="myDropdown" class="dropdown-content">
                    <div class="support-num">
                        <span>  Restaurant&nbsp;:&nbsp; +91 {{branchContact}}</span>
                        <span>  Support&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;+91 8885025585</span>
                    </div>
                </div>
                <!-- </div>
                        </div>
                    </div> -->
            </div>
        </div>
    </div>
    } @if(storeOpt){
    <div class="accountbox-wrapper is-visible">
        <div class="body-overlay"></div>
        <div class="accountbox text-left support-popup" style="    width: 40%;">
            <!-- <span class="close-icon" title="Close">
                        <a (click)="selectBranch(99)"><i class="zmdi zmdi-close" ></i></a>

                    </span> -->
            <ul class="nav accountbox__filters" style="border-bottom: solid 1px #c2c2c2;">
                <li style="text-align: center;width: 100%;padding: 0px;" class="section__title title__style--2">
                    <div class="food__menu__content">
                        <h2 style="font-size:20px;color: #d50c0d;padding-bottom: 10px;">Choose Store </h2>
                    </div>
                </li>
            </ul>
            <hr>
            <div class="accountbox__inner tab-content">
                <div class="accountbox__login tab-pane fade show active" style="width: 100%;">
                    <div class="single-input">

                        <div class="dropdown">
                            <div id="myDropdown" class="dropdown-content">
                                <div class="row" style="margin-bottom: 20px;">
                                    @for (item of vendorData.restaurantDetails; track $index) {
                                        <div class="col-md-6 mb-3" (click)="selectBranch($index)" >
                                            <div class="storePanel">
                                                <div class="storeName"> {{item.restaurantName}} </div>
                                                <div class="storeAddr"> {{item.address}}</div>
                                                <div class="bottomContent">
                                                    <!-- <div class="distance">  </div>
                                                    <div class="storeStatus">Closed for Delivery</div> -->
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <!-- <div class="col-md-6">
                                        <div class="storePanel">
                                            <div class="storeName">Ankapur</div>
                                            <div class="storeAddr">H.No. 1-98/6A/14, Plot no 42 Road no.3, Patrika nagar Hitech City Nagar, Madhapur
                                            </div>
                                            <div class="bottomContent">
                                                <div class="distance">1.2 Kms</div>
                                                <div class="storeStatus">Closed for Delivery</div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    }

    @if (conformChangeBranch) {
        <div class="accountbox-wrapper is-visible">
            <div class="body-overlay"></div>
            <div class="accountbox text-left" style="padding: 30px;">
                <span class="close-icon" title="Close" (click)="conformChangeBranch = !conformChangeBranch">
                        <i class="zmdi zmdi-close"></i>
                    </span>
                <ul class="nav accountbox__filters" style="border-bottom: solid 1px #c2c2c2;">
                    <li style="text-align: center;width: 100%;padding: 0px;" class="section__title title__style--2">
                      <div class="food__menu__content">
                        <h2 style="font-size:20px;color: #d50c0d;padding-bottom: 10px;">Warning </h2>
                    </div>
                    </li>
                </ul>
                <hr>
                <div class="accountbox__inner tab-content">
                    <div class="accountbox__login tab-pane fade show active" style="width: 100%;">
                        <div class="food__menu__content">
                            <h6 style="font-size:20px;color: #d50c0d;    padding: 10px 0;">On changing branch your added items will be removed. </h6>
                        </div>
                        <div class="single-input" style="text-align: center;">
                            <button (click)="confirmChangeBranch()" type="button" class="food__btn" style="width: 30%;margin-right: 10px;font-size: 15px;padding: 0px;"><span>Change</span></button>
                            <button (click)="conformChangeBranch = !conformChangeBranch" type="button" class="food__btn" style="width: 30%;font-size: 15px;padding: 0px;"><span>Cancel</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }


    <!-- End Mainmenu Area -->
</header>
