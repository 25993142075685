import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'safe',
  standalone: true,
  pure: false
})
export class SafePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}
  transform(url:string): unknown {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
    // return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
