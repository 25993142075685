import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyStringCheck',
  standalone: true
})
export class EmptyStringCheckPipe implements PipeTransform {

  transform(value: any, listType:string): string {
    const rData = (!value || value.trim() === '') ? (listType+' X ') : value;
    return rData;
  }

}
