<div class="accountbox-wrapper is-visible">
    <div class="body-overlay"></div>
    <div class="accountbox text-center">
        <div class="location-dia">
            <span>Please Enter Address</span>
        </div>
        <div class="accountbox__inner">
            <div class="accountbox__login">
                <div class="row">
                    <div class="col-lg-12 btn-container">
                        <input type="text" [(ngModel)]="searchTerm" (input)="search()" placeholder="Search...">
                        <div class="search-results" *ngIf="showResults">
                            <div class="result" *ngFor="let result of searchResults" (click)="selectResult(result)">
                                {{ result.text }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>