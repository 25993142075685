<div class="accountbox-wrapper is-visible">
    <div class="body-overlay"></div>
    <div class="accountbox text-left" style="padding: 20px;">
        <ul class="nav accountbox__filters">
            <li style="text-align: center;width: 100%;padding: 0px;" class="section__title title__style--2">
                <a class="active title__line" aria-selected="true" style="font-weight: bold;font-size:24px;">
                    {{menuItem}} 
                </a><br>
                <span class="customize-title">Customise as per your taste</span>
`
            </li>
        </ul>
        <div class="accountbox__inner tab-content">
            @if (variations.length > 0) {
            <div class="card">
                <div class="food__menu__content">
                    <h2 style="font-size:14px;">Choice of Variation</h2>
                </div>
                <div class="varient-wrapper">
                    <ul class="checkout-method-list" style="    margin: 8px 0px;">
                        @for (item of tvariations; track item; let index = $index) {
                        <!-- <button [ngClass]="{'selected-varient btn-primary': index == selectedVarient}" class="btn btn-outline-primary varient-btn" (click)="selectVariet(index)">
                        <span> {{item.name}} </span>
                    </button> -->
                        <li [ngClass]="{'selected-varient btn-primary active': index == selectedVarient}" (click)="selectVariet(index)" data-form="checkout-login-form" style="color: #232323;">{{item.name}}</li>
                        }
                    </ul>

                </div>
                <div class="price" style="font-family: 'Inter';">Varient Price <span style="color: #d50c0d;">+ ₹ {{tvariations[selectedVarient].price}}</span></div>
                <div class="border-bottom " style="margin: 10px 0px;border-bottom: solid 1px #d50c0d !important;"></div>
                
                @if (tvariations[selectedVarient].addonGroups.length) {
                <div class="food__menu__content">
                    <h2 style="font-size:14px;">Add-ons</h2>
                </div>
                <div class="add-on-txt">You can add more of your choice</div>
                <div class="add-on-wrapper">
                    <form [formGroup]="variationForm">
                        @for (addOngrp of getadOngroup().controls; track addOngrp; let grpindex = $index) {
                        <div class="add-on-group" formArrayName="addOngrp">
                            <div class="food__menu__content">
                                <h2 style="font-size:14px;">
                                    {{tvariations[selectedVarient].addonGroups[grpindex].addonGroupName}}</h2>
                            </div>
                            <div class="add-ons" [formGroupName]="grpindex" style="margin-top: 5px;">
                                @for (addonEle of getaddonControl(grpindex); track addonEle; let addonindex = $index) {
                                <div formArrayName="addons">
                                    <div class="form-check" [formGroupName]="addonindex" style="padding: 0px;">
                                        <label class="form-check-label" style="font-family: 'Inter';" for="['addonIndex']">
                                            <div class="checkbox">
                                                <label class="i-checks">
                                                    <input type="checkbox"  [formControlName]="tvariations[selectedVarient].addonGroups[grpindex].addonItems[addonindex].id" type="checkbox" id="['tvariations[selectedVarient].addonGroups[grpindex].addonItems[addonindex]']"><i></i> {{tvariations[selectedVarient].addonGroups[grpindex].addonItems[addonindex].addonItemName}}</label>
                                    </div>
                                    </label>
                                    <span class="pull-right" style="color: #d50c0d;">₹
                                            {{tvariations[selectedVarient].addonGroups[grpindex].addonItems[addonindex].addonItemPrice}}</span>
                                    <!-- <input class="form-check-input" [formControlName]="tvariations[selectedVarient].addonGroups[grpindex].addonItems[addonindex].id" type="checkbox" id="['tvariations[selectedVarient].addonGroups[grpindex].addonItems[addonindex]']"> -->



                                </div>
                            </div>
                            }
                        </div>
                </div>
                }
                </form>
            </div>
            }
        </div>
        } @if (addOnChoice.length >0) {`
        <div class="card">
            <div class="food__menu__content">
                <h2 style="font-size:14px;">Choice of Addons</h2>
            </div>
            <div class="add-on-wrapper">
                <form [formGroup]="addonForm">
                    <div formArrayName="addOngrp">
                        @for (addonChoice of getadOnchoicegroup().controls; track addonChoice; let addonchoiceindex = $index) {
                        <div class="form-check" [formGroupName]="addonchoiceindex" style="padding: 0px;">
                            <label class="form-check-label" for="['addonchoiceindex']">
                                        <div class="checkbox">
                                            <label class="i-checks">
                                                <input type="checkbox"  [formControlName]="taddOnChoice[0].addonItems[addonchoiceindex].id" type="checkbox" id="['taddOnChoice[0].addonItems[addonchoiceindex]']"><i></i> {{taddOnChoice[0].addonItems[addonchoiceindex].addonItemName}}</label>
                        </div>
                        </label>
                        <span class="pull-right" style="color: #d50c0d;">₹
                                        {{taddOnChoice[0].addonItems[addonchoiceindex].addonItemPrice}}</span>
                        <!-- <input class="form-check-input" [formControlName]="taddOnChoice[0].addonItems[addonchoiceindex].id" type="checkbox" id="['taddOnChoice[0].addonItems[addonchoiceindex]']"> -->
                    </div>
                    }
            </div>

            </form>
        </div>
    </div>

    }

    <!-- <span class="accountbox-close-button" (click)="closeDialogue()"><i class="zmdi zmdi-close"></i></span> -->
    <div class="btn-container">
        <!-- <span>₹ {{totalPrice}}</span> -->
        <!-- <button class="btn btn-outline-success" (click)="addCart()">Add to cart</button> -->
        <div class="grid__show__btn" style="float: right;margin:5px 0 3px 0;">
            <a class="food__btn" style="cursor: pointer;" (click)="addCart()">Add to cart</a>
        </div>
        <div class="grid__show__btn btnClose" style="float: right;margin:5px 0 3px 0; ">
            <a class="food__btn" style="cursor: pointer;background-color: transparent;border:solid 1px #d50c0d;margin-right:15px;" (click)="closeDialogue()">Close</a>
        </div>
    </div>

</div>
</div>
</div>