import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delivery-not-available',
  standalone: true,
  templateUrl: './delivery-not-available.component.html',
  styleUrls: ['./delivery-not-available.component.scss']
})
export class DeliveryNotAvailableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
