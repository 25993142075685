import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { HeaderComponent } from "../../../theme/components/header/header.component";
import { FooterComponent } from "../../../theme/components/footer/footer.component";
import { UnserviceableComponent } from "../../../unserviceable/unserviceable.component";
import { FormsModule } from '@angular/forms';
import { Observable, Subscription, catchError, debounceTime, distinctUntilChanged, of, switchMap } from 'rxjs';
import { ApiService } from '../../../core/services/api.service';
import { FoodMenuComponent } from '../food-menu.component';
import { Config } from '../../../core/config';
import { StoreClosedComponent } from "../../../store-closed/store-closed.component";
import { WebSocketService } from '../../../core/services/websocket.service';

interface LocationData {
  id: string;
  formattedAddress: string;
  location: {
    latitude: number;
    longitude: number;
  };
}

@Component({
    selector: 'app-deliverymode',
    standalone: true,
    templateUrl: './deliverymode.component.html',
    styleUrl: './deliverymode.component.scss',
    imports: [SearchBarComponent, CommonModule, HeaderComponent, FooterComponent, FormsModule, CommonModule, UnserviceableComponent, StoreClosedComponent]
})
export class DeliverymodeComponent {

  showSearchBar: boolean = false;
  unServiceableValue: boolean = false;
  activeTab: number = 1;
  locationEnabled: boolean = true;
  restaurentId: number | undefined;
  // restaurentId: number = Number(Config.rest_id);
  partnerId: string = Config.partnerId;
  restaurentActive: boolean = true;
  currentPage = "deliveryMode";
  private wsSubscription!: Subscription;
 
  @Output() closeDelivery = new EventEmitter<any>();

  showDeliveryPopup() {
    this.showSearchBar = true;
  }

  closeSearchBar() {
    this.showSearchBar = false;
  }

  searchTerm: string = '';
  searchPlaceId: string = '';
  searchResults: { placeId: string, text: string }[] = [];
  showResults: boolean = false;

  constructor(public apiService: ApiService, private foodMenuComponent: FoodMenuComponent,
    private wsService: WebSocketService
  ) { }

  search(): void {
    if (this.searchTerm.length > 0) {
      this.showResults = true;
      this.fetchPlacePrediction().subscribe(results => {
        this.searchResults = results;
      });
    } else {
      this.showResults = false;
    }
    this. ngOnInit();
  }

  ngOnInit(): void {

    let restId:any = localStorage.getItem("selectedRestId")
    this.restaurentId = parseInt(restId);
    
    if(this.restaurentId != undefined && !isNaN(this.restaurentId)){
      this.isLocationEnabled();
      this.getRestaurantDetails();
    }
    
    
    this.wsSubscription = this.wsService.getRestaurantStatusUpdates().subscribe((webSocketResponse: any) => {
      this.restaurentActive = webSocketResponse.store_status == 0 ? false : true;
    });
  }


  ngOnDestroy(): void {
    if (this.wsSubscription) {
      this.wsSubscription.unsubscribe();
    }
  }

  fetchPlacePrediction(): Observable<{ placeId: string, text: string }[]> {
    return this.apiService.getMethod(`/location/maps/predict?search=${this.searchTerm}`).pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(response => {
        const suggestions: any[] = response?.data?.[0]?.suggestions || [];
        const placeIdTextArray = suggestions.map(suggestion => {
          const placeId = suggestion.placePrediction.placeId;
          const text = suggestion.placePrediction.text.text;
          return { placeId, text };
        });
        return of(placeIdTextArray);
      })
    );
  }

  getPlaceDetails(): Observable<{ placeId: string, text: string }[]> {
    console.log(this.restaurentId);
    // return this.apiService.getMethod(`/location/maps/place/${this.restaurentId}?placeId=${this.searchPlaceId}`).pipe(
    return this.apiService.getMethod(`/location/maps/place/${this.partnerId}?placeId=${this.searchPlaceId}`).pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(response => {
        const suggestions: any[] = response?.data?.[0]?.suggestions || [];
        const placeIdTextArray = suggestions.map(suggestion => {
          const placeId = suggestion.placePrediction.placeId;
          const text = suggestion.placePrediction.text.text;
          return { placeId, text };
        });
        return of(placeIdTextArray);
      }),
      catchError(error => {
        console.error('Error fetching results:', error);
        return of([]);
      })
    );
  }

  selectResult(result: { placeId: string, text: string }): void {

    this.searchTerm = result.text;
    this.showResults = false;
    this.searchPlaceId = result.placeId;
    if (this.restaurentActive == false) return;
    console.log(this.restaurentId);
    // this.apiService.getMethod(`/location/maps/place/${this.restaurentId}?placeId=${result.placeId}`).subscribe({
    this.apiService.getMethod(`/location/maps/place/${this.partnerId}?placeId=${result.placeId}`).subscribe({
      next: (response: { data: LocationData[] }) => {
        console.log(response.data[0]);
        
        const locationData = response.data[0];
        locationData.formattedAddress = '';
        if (Object(locationData).addressOne != null) locationData.formattedAddress += Object(locationData).addressOne + ', ';
        if (Object(locationData).addressTwo != null) locationData.formattedAddress += Object(locationData).addressTwo + ', ';
        if (Object(locationData).addressType != null) locationData.formattedAddress += Object(locationData).addressType + ', ';
        if (Object(locationData).city != null) locationData.formattedAddress += Object(locationData).city + ', ';
        if (Object(locationData).country != null) locationData.formattedAddress += Object(locationData).country + ', ';
        if (Object(locationData).landmark != null) locationData.formattedAddress += Object(locationData).landmark + ', ';
        if (Object(locationData).state != null) locationData.formattedAddress += Object(locationData).state;
        if (Object(locationData).pincode != null) locationData.formattedAddress += ' - ' + Object(locationData).pincode + '. ';

        localStorage.setItem('selectedLocation', JSON.stringify(locationData));

        this.closeSearchBar();
        // this.foodMenuComponent.closeDeliveryMode();
        this.foodMenuComponent.loadAddress();
        this.closeDelivery.emit({ action: 'closeDelivery' })
        // Reset searchTerm and searchResults
        this.searchTerm = '';
        this.searchResults = [];
        this.showResults = false;

      },
      error: error => {
        if (error.status === 400) {
          this.unServiceable()
          console.error('Bad request error:', error);
        }
        console.error('Error fetching location data:', error);
      }
    });
  }

  getMyCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          this.apiService.getMethod(`/location/maps/geocode/${this.restaurentId}?latitude=${latitude}&longitude=${longitude}`).subscribe({
            next: (response: { data: LocationData[] }) => {
              const locationData = response.data[0];
              locationData.formattedAddress = '';
              if (Object(locationData).addressOne != null) locationData.formattedAddress += Object(locationData).addressOne + ', ';
              if (Object(locationData).addressTwo != null) locationData.formattedAddress += Object(locationData).addressTwo + ', ';
              if (Object(locationData).addressType != null) locationData.formattedAddress += Object(locationData).addressType + ', ';
              if (Object(locationData).city != null) locationData.formattedAddress += Object(locationData).city + ', ';
              if (Object(locationData).country != null) locationData.formattedAddress += Object(locationData).country + ', ';
              if (Object(locationData).landmark != null) locationData.formattedAddress += Object(locationData).landmark + ', ';
              if (Object(locationData).state != null) locationData.formattedAddress += Object(locationData).state;
              if (Object(locationData).pincode != null) locationData.formattedAddress += ' - ' + Object(locationData).pincode + '. ';

              localStorage.setItem('selectedLocation', JSON.stringify(locationData));

              this.closeSearchBar();
              // this.foodMenuComponent.closeDeliveryMode();
              this.foodMenuComponent.loadAddress();
              this.closeDelivery.emit({ action: 'closeDelivery' })
              // Reset searchTerm and searchResults
              this.searchTerm = '';
              this.searchResults = [];
              this.showResults = false;
            },
            error: (error) => {
              if (error.status === 400) {
                this.unServiceable()
                console.error('Bad request error:', error);
              }
              console.error('Error fetching current location:', error);
            }
          });
        },
        (error) => {
          console.error('Error getting current location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  unServiceable() {
    this.unServiceableValue = true;
  }


  closeUnServiceable() {
    this.unServiceableValue = false;
  }

  isLocationEnabled() {
    if ('permissions' in navigator) {
      navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
        console.log(permissionStatus.state)
        if (permissionStatus.state === 'denied') {
          this.locationEnabled = false;
        }
      }).catch(error => {
        console.error('Error checking geolocation permission:', error);
      });
    }
  }

  getRestaurantDetails():void {
    this.apiService.getMethod(`/restaurant/${this.restaurentId}`).subscribe({
      next: (response) => {
        this.restaurentActive = response.data[0].active;
        localStorage.setItem('restaurantDetails', JSON.stringify(response.data[0]));
      },
      error: (error) => {
        if (error.status === 400 || error.status === 404) {
          this.unServiceable()
          console.error('Restaurant get request error:', error);
        }
        console.error('Error fetching restaurant Details:', error);
      }
    });
  }

}
