import { Component } from '@angular/core';
import { FooterComponent } from "../../theme/components/footer/footer.component";
import { HeaderComponent } from "../../theme/components/header/header.component";
import { CommonModule } from '@angular/common';
import { FormsModule, } from '@angular/forms';
import { AbstractControl, FormControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApiService } from '../../core/services/api.service';
import { TableModule } from 'primeng/table';
import { RemoveSpecialCharacterPipe } from "../../core/pipes/remove-special-character.pipe";
import { AddressComponent } from "../address/address.component";
import { Router } from '@angular/router';

@Component({
    selector: 'app-user-profile',
    standalone: true,
    templateUrl: './user-profile.component.html',
    styleUrl: './user-profile.component.scss',
    imports: [FooterComponent, HeaderComponent, CommonModule, FormsModule, ReactiveFormsModule, TableModule, RemoveSpecialCharacterPipe, AddressComponent]
})
export class UserProfileComponent {
  currentPage: string = "user";
  activePage: number = 1;
  address: any = [];
  addNew: boolean = false;
  addressForm!: FormGroup;
  submitted = false;
  customerDetails: any = [];
  selectedLocation: any = {};
  orderHistory:any = [];
  showTable:boolean = true;
  viewItem: any = {};
  showAddressHeader:boolean = false;
  addressDetails!: any;
  constructor(
    private formBuilder: FormBuilder, public apiService: ApiService,private router: Router) {

  }

  ngOnInit() {
    this.addressForm = this.formBuilder.group({
      addressType: new FormControl('Home', [Validators.required]),
      addressOne: new FormControl('', [Validators.required]),
      addressTwo: new FormControl('', [Validators.required]),
      landmark: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      country: new FormControl('India', [Validators.required]),
      pincode: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{6}$')])
    });
    let custDetail: any = localStorage.getItem('customerDetails');

    this.selectedLocation = localStorage.getItem('selectedLocation');
    this.customerDetails = JSON.parse(custDetail);
    this.getCusomerDetails();

    this.getOrderHistory();
  }

  addNewAddress() {
    this.addNew = !this.addNew;
  }

  submitAddressForm(): void {
    // Call API to send login details
    this.submitted = true;
    if (this.addressForm.invalid) {
      return;
    }
  }

  get addressFormError(): { [key: string]: AbstractControl } {
    return this.addressForm.controls;
  }

  getCusomerDetails() {
    this.apiService.getMethod(`/customer/${this.customerDetails.id}`).subscribe({
      next: (reponse) => {
        this.customerDetails = reponse.data[0];
        console.log(this.customerDetails);
        if (this.customerDetails.addresses != undefined) {
          this.address = reponse.data[0].addresses;
        }
      },
      error: (error) => { console.log(error) }
    })
  }
  addAddress() {
    this.submitted = true;
    if (this.addressForm.invalid) return;
    if (this.addressForm.valid) {
      console.log(this.addressForm.value);

      this.apiService.postMethod(`/customer/${this.customerDetails.id}/address`, this.addressForm.value).subscribe({
        next: (reponse) => {
          this.address = reponse.data[0].addresses;
          this.addNew = !this.addNew;
        },
        error: (error) => { console.log(error) }
      });
    }

  }
  closeAddressForm() {
    this.addNew = !this.addNew;
  }

  /**
   * To fetch order history
   */
  getOrderHistory() {
    this.apiService.getMethod(`/order?customerId_eq=${this.customerDetails.id}`).subscribe({
      next: (reponse) => {
        this.orderHistory = reponse.data;
        let SNo:number = 1;
        this.orderHistory.map((ele:any) => {
          ele['SNo'] = SNo;
          SNo += 1;
        })
        console.log(this.orderHistory);
      },
      error: (error) => { console.log(error) }
    })
  }
  expandView(item:any){
    console.log(item);

    this.apiService.getMethod(`/address/${item.deliveryDetails.addressId}`).subscribe({
      next: (response) =>{
        // this.addressDetails = response.data[0];
        const tempcustomDetailsformattedAddress = {
          addressOne: response.data[0].addressOne,
          addressTwo: response.data[0].addressTwo,
          addressType: response.data[0].addressType,
          landmark: response.data[0].landmark,
          city: response.data[0].city,
          state: response.data[0].state,
          country: response.data[0].country,
          pincode: response.data[0].pincode
      }
      this.addressDetails = Object.values(tempcustomDetailsformattedAddress).filter(part => part !== null && part !== undefined).join(', ');
        console.log(this.addressDetails);
        
        this.showTable = false;
        this.viewItem = item;
      },
      error: (error) => { console.log(error) }
    })

   
  }

  
/**
 * Logout
 */
logout():void{
  localStorage.clear();
  this.router.navigateByUrl('/order');
}
}

