<div class="accountbox-wrapper is-visible">
    <div class="body-overlay"></div>
    <div class="accountbox text-left">
        <ul class="nav accountbox__filters">
            <li class="section__title title__style--2">
                <img src="../../assets/images/store-closed.png" alt="store closed image">
                <a class="active title__line">We're Currently Closed</a>
                <br><span style="color:black;">Our restaurant is not accepting orders at this time. Please check back during our operating hours.</span>
            </li>
        </ul>
        <div class="accountbox__inner tab-content">
            <div class="accountbox__login tab-pane fade show active">
            </div>
        </div>
    </div>
</div>