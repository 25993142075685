import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { FoodMenuComponent } from './pages/food-menu/food-menu.component';
import { CartComponent } from './pages/cart/cart.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { MenuComponent } from './pages/menu/menu.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { LoginComponent } from './pages/food-menu/login/login.component';
import { OrderTrackingComponent } from './pages/order-tracking/order-tracking.component';

import { authGuard } from './core/gaurds/auth.guard';
import { AddressComponent } from './pages/address/address.component';
import { PaymentComponent } from './pages/payment/payment.component';

export const routes: Routes = [
    {
        path:"", redirectTo:'order', pathMatch:'full'
    },
    {
        path:'home', component: HomeComponent
    },
    {
        path:'order', 
        component:FoodMenuComponent
    },
    {
        path:'cart', 
        component:CartComponent,
        canActivate:[authGuard]
    },
    {
        path:'addAddress', 
        component:AddressComponent,
        canActivate:[authGuard]
    },
    {
        path:'checkout', 
        component:CheckoutComponent,
        canActivate:[authGuard]
    },
    {
        path:'payment',
        component:PaymentComponent,
        canActivate:[authGuard]
    },
    // { 
    //     path:'about', component:AboutUsComponent
    // },
    // {
    //     path:'menu', component:MenuComponent
    // },
    // {
    //     path:'gallery', component:GalleryComponent
    // },
    // {
    //     path:'contact', component : ContactUsComponent
    // },
    {
        path:"user", 
        component:UserProfileComponent,
        canActivate:[authGuard]
    },
    {
        path:"login",
        component:LoginComponent
    },
    {
        path:'order-tracking', 
        component:OrderTrackingComponent
    },
    {
        // path:"**", redirectTo:'home', pathMatch:'full'
        path:"**", redirectTo:'order', pathMatch:'full'
    }
];
