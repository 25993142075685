<div >
    <app-header [pageName]="currentPage"></app-header>
      
    <!-- Start Slider Area -->
    <div class="slider__area slider--one">
        <div class="slider__activation--1">
            <!-- Start Single Slide -->
            <div class="slide fullscreen bg-image--1">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="slider__content">
                                <div class="slider__inner">
                                    <h2>Ankapur</h2>
                                    <h1>food delivery & service</h1>
                                    <div class="slider__input">
                                        <input type="text" placeholder="Type Place, City.Division">
                                        <h5 class="or">or</h5>
                                        <div class="location__btn">Use my location</div>
                                        <!-- <input class="res__search" type="text" placeholder="Restaurant"> -->
                                        <!-- <div class="src__btn">
                                            <a href="#">Search</a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Single Slide -->
        </div>
    </div>
    <!-- End Slider Area -->
    
    <!-- Start Food Category -->
    <section class="food__category__area bg--white section-padding--lg bg-image--3">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <div class="section__title service__align--left">
                        <p>the process of our service</p>
                        <h2 class="title__line">Search by food category</h2>
                    </div>
                </div>
            </div>
            <div class="food__category__wrapper mt--40">
                <div class="row">
                    <!-- Start Single Category -->
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="food__item foo">
                            <div class="food__thumb">
                                <a href="menu-details.html">
                                    <img src="../../../assets/images/product/md-product/1.jpg" alt="product images">
                                </a>
                            </div>
                            <div class="food__title">
                                <h2><a href="menu-details.html">Breakfast Iteam</a></h2>
                            </div>
                        </div>
                    </div>
                    <!-- End Single Category -->
                    <!-- Start Single Category -->
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="food__item foo">
                            <div class="food__thumb">
                                <a href="menu-details.html">
                                    <img src="../../../assets/images/product/md-product/2.jpg" alt="product images">
                                </a>
                            </div>
                            <div class="food__title">
                                <h2><a href="menu-details.html">Lunch Iteam</a></h2>
                            </div>
                        </div>
                    </div>
                    <!-- End Single Category -->
                    <!-- Start Single Category -->
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="food__item foo">
                            <div class="food__thumb">
                                <a href="menu-details.html">
                                    <img src="../../../assets/images/product/md-product/3.jpg" alt="product images">
                                </a>
                            </div>
                            <div class="food__title">
                                <h2><a href="menu-details.html">Dinner Iteam</a></h2>
                            </div>
                        </div>
                    </div>
                    <!-- End Single Category -->
                </div>
            </div>
        </div>
    </section>
    <!-- End Food Category -->
    <!-- Start Service Area -->
    <section class="fd__service__area bg-image--2 section-padding--xlg">
        <div class="container">
            <div class="service__wrapper bg--white">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="section__title service__align--left">
                            <p>The process of our service</p>
                            <h2 class="title__line">How it work</h2>
                        </div>
                    </div>
                </div>
                <div class="row mt--30">
                    
                    <!-- Start Single Service -->
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <div class="service">
                            <div class="service__title">
                                <div class="ser__icon">
                                    <img src="../../../assets/images/icon/color-icon/2.png" alt="icon image">
                                </div>
                                <h2><a href="service.html">Select, you love to eat</a></h2>
                            </div>
                            <div class="service__details">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
                            </div>
                        </div>
                    </div>
                    <!-- End Single Service -->
                    <!-- Start Single Service -->
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <div class="service">
                            <div class="service__title">
                                <div class="ser__icon">
                                    <img src="../../../assets/images/icon/color-icon/3.png" alt="icon image">
                                </div>
                                <h2><a href="service.html">Pickup or delivery</a></h2>
                            </div>
                            <div class="service__details">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
                            </div>
                        </div>
                    </div>
                    <!-- End Single Service -->
                </div>
            </div>
        </div>
    </section>
    <!-- End Service Area -->
    <!-- Start Download App Area -->
    <section class="food__download__app__area section-padding--lg bg--white bg__shape--1">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <div class="section__title service__align--left">
                        <p>the process of our service </p>
                        <h2 class="title__line">Download our app</h2>
                    </div>
                </div>
            </div>
            <div class="row mt--80">
                <div class="col-lg-12 poss--relative">
                    <div class="app__download__container">
                        <div class="app__download__inner inline__image__css--1" style="background-image: url(../../../assets/images/app/bg.png);">
                            <h2>App is now in your hand</h2>
                            <h6>Download! to get this app Faster way to order food</h6>
                        </div>
                        <ul class="dwn__app__list">
                            <li class="wow lightSpeedIn" data-wow-delay="0.2s"><a href="#"><img src="../../../assets/images/app/2.png" alt="app images"></a></li>
                            <li class="wow lightSpeedIn" data-wow-delay="0.3s"><a href="#"><img src="../../../assets/images/app/3.png" alt="app images"></a></li>
                        </ul>
                    </div>
                    <div class="app__phone wow fadeInLeft" data-wow-delay="0.2s">
                        <img src="../../../assets/images/app/1.png" alt="app images">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Download App Area -->
    
    <app-footer></app-footer>
  </div>
