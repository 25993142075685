import { Component } from '@angular/core';
import { DeliverymodeComponent } from '../pages/food-menu/deliverymode/deliverymode.component';

@Component({
  selector: 'app-store-closed',
  standalone:true,
  templateUrl: './store-closed.component.html',
  styleUrls: ['./store-closed.component.scss'],
})
export class StoreClosedComponent {

}
