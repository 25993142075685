<div>
    <app-header [pageName]="currentPage"></app-header>
    <app-unserviceable *ngIf="unServiceableValue"></app-unserviceable>
    <section class="food__about__us__area bg--white" *ngIf="!unServiceableValue">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="food__gallery__area  bg--white">
                        <div class="container portfolio__area">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="menu__nav nav nav-tabs portfolio__menu d-flex flex-wrap flex-md-nowrap flex-lg-nowrap justify-content-center">
                                        <button (click)="activeTab=1;showDeliveryPopup();" [ngClass]="{'is-checked':activeTab==1}">Delivery</button>
                                        <!-- <button (click)="activeTab=2;showDeliveryPopup();" [ngClass]="{'is-checked':activeTab==2}">Pickup</button> -->
                                    </div>
                                </div>
                                <div *ngIf="activeTab==1" class="section__title title__style--2 service__align--center  wow fadeIn">
                                    <h4 class="title__line">Set your delivery address to get started</h4>
                                    <p>Start ordering now! </p>
                                </div>

                                <!-- <div *ngIf="activeTab==2" class="section__title title__style--2 service__align--center  wow fadeIn">
                                    <h4 class="title__line">Pickup items from your nearest store. On the go!.</h4>
                                    <p>Let's get ordering </p>
                                </div> -->
                            </div>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 btn-container">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                            <button class="food__btn" (click)="getMyCurrentLocation()">
                                                            <i class="fa fa-location-arrow icon" aria-hidden="true" ></i>Use my current location</button>
                                            <span style="color: #000;">OR</span>
                                            <div class="dropdown">
                                                <div id="myDropdown" class="dropdown-content">
                                                    <input type="text" placeholder="Start searching for street or locality" [(ngModel)]="searchTerm" (input)="search()">
                                                    <i class="fa fa-search"></i>
                                                    <a *ngIf="showResults">
                                                        <div class="result" *ngFor="let result of searchResults" (click)="selectResult(result)" style="cursor: pointer;">
                                                            {{ result.text }}
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        
    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="locationEnabled==false" class="section__title service__align--center  wow fadeIn location-enable" >
                            <p>Please enable your location access.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="fd__service__area">
        <div class="container-fluid">
            <div class="service__wrapper bg--white">
                <div class="row ">
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <div class="service">
                            <div class="service__title">
                                <div class="ser__icon">
                                    <i class="fa fa-map-marker"></i>
                                </div>
                                <h2>Enter Delivery Address</h2>
                            </div>
                            <div class="service__details">
                                <p>Start by entering your delivery address to check availability and view our menu options for your location.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <div class="service">
                            <div class="service__title">
                                <div class="ser__icon">
                                    <i class="fa fa-shopping-cart"></i>
                                </div>
                                <h2>Explore Menu</h2>
                               
                            </div>
                            <div class="service__details">
                                <p>Discover our diverse menu options, choose dishes from various cuisines, and customize your order.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <div class="service">
                            <div class="service__title">
                                <div class="ser__icon">
                                    <i class="fa fa-truck"></i>
                                </div>
                                <h2>Confirm & Place Order</h2>
                            </div>
                            <div class="service__details">
                                <p>Review your selections, confirm delivery details, choose a payment method, and complete your order securely.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-store-closed *ngIf="!restaurentActive"></app-store-closed>
</div>