import { Component } from '@angular/core';
import { DeliverymodeComponent } from "../pages/food-menu/deliverymode/deliverymode.component";

@Component({
  selector: 'app-unserviceable',
  standalone: true,
  templateUrl: './unserviceable.component.html',
  styleUrl: './unserviceable.component.scss',
  imports: [DeliverymodeComponent]
})
export class UnserviceableComponent {
  
  constructor( private deliverymodeComponent:DeliverymodeComponent
  ) { }
  
  closeUnserviceable() {
    this.deliverymodeComponent.unServiceableValue = false;
  }

}
