import { Component, ElementRef, Renderer2, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-iframe-component',
  standalone: true,
  imports: [],
  templateUrl: './iframe-component.component.html',
  styleUrl: './iframe-component.component.scss'
})
export class IframeComponentComponent implements OnInit {

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit() {

  //   $('iframe').load( function() {
  //     $('.jss1').add
  // });
  $(document).ready(function(){
    $('.jss1').css('display','none');
});
  
  }

}
